import RecipientDetails from './RecipientDetails'
import DeliveryDetails from './DeliveryDetails'

class Voucher {
    constructor(voucher) {
        this.voucherId = voucher ? voucher.voucherId : voucher.voucherId;
        this.cost = voucher ? voucher.cost : voucher.cost;
        this.description = voucher ? voucher.description : voucher.description;
        this.maximumQuantity = voucher ? voucher.maximumQuantity : voucher.maximumQuantity;
        this.minimumQuantity = voucher ? voucher.minimumQuantity : voucher.minimumQuantity;
        this.monthsValid = voucher ? voucher.monthsValid : voucher.monthsValid;
        this.name = voucher ? voucher.name : voucher.name;
        this.selectedQuantity = voucher ? voucher.minimumQuantity : voucher.minimumQuantity;
        this.termsAndConditions = voucher ? voucher.termsAndConditions : voucher.termsAndConditions;
        this.sendingOption = null;
        this.deliveryMethod = null;
        this.recipientDetails = new RecipientDetails();
        this.deliveryDetails = new DeliveryDetails();
        this.references = [];
        this.uniqueIdentifier = '_' + Math.random().toString(36).substr(2, 9);
    }
}
export default Voucher;
