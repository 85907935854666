import React, {useContext, Fragment} from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import SetupContext from './../contexts/SetupContext';
import { ReactComponent as CloseIcon } from '../content/icons/close.svg';
import NumericInputField from './NumericInputField'
import deliveryMethod from '../enums/deliveryMethod'

function VoucherCartItem(props) {
    const setupContext = useContext(SetupContext);

    function getAddress() {
        return `${props.cartItem.deliveryDetails.house} ${props.cartItem.deliveryDetails.street}, 
                ${props.cartItem.deliveryDetails.town}, ${props.cartItem.deliveryDetails.postcode}`;
    }

    return (
        <div className="voucher-cart-item">
            <div className={props.isTabletView ? "" : "d-flex"}>
                <div className="voucher-price">{setupContext.currencySymbol}{props.cartItem.unitCost}</div>
                <div className="voucher-information">
                    <div className="d-flex">
                        <h6 className="text-uppercase voucher-name">{props.cartItem.name} </h6>
                        <div className="remove-icon" onClick={() => props.removeOrderItem(props.cartItem.uniqueIdentifier)}><CloseIcon /></div>
                    </div>
                    {props.cartItem.recipientDetails && props.cartItem.recipientDetails.firstName && props.cartItem.recipientDetails.lastName &&
                        <div className="recipient-information">
                            <FormattedMessage id={"Vouchers.SendTo"} tagName="h6"/>
                            <Fragment>
                                {props.cartItem.deliveryMethod === deliveryMethod.email && props.cartItem.recipientDetails.email &&
                                    <div className="recipient">{props.cartItem.recipientDetails.firstName} {props.cartItem.recipientDetails.lastName} ({props.cartItem.recipientDetails.email})</div>
                                }
                                {props.cartItem.deliveryMethod === deliveryMethod.delivery &&
                                    <div className="recipient">{props.cartItem.recipientDetails.firstName} {props.cartItem.recipientDetails.lastName} ({getAddress()}) </div>
                                }
                                <div className="message">{props.cartItem.recipientDetails.message}</div>
                            </Fragment>
                        </div>
                    }
                    <div className="d-flex quantity-wrapper">
                        <NumericInputField value={props.cartItem.quantity} max={props.cartItem.maximumQuantity} onChangeHandler={props.updateCartItemQuantity} itemId={props.cartItem.uniqueIdentifier} />
                        <h6 className="item-price">{setupContext.currencySymbol}{(props.cartItem.quantity * props.cartItem.unitCost).toFixed(2)}</h6>
                    </div>
                    {props.deliveryMethod && 
                        <div className="d-flex delivery-information">
                            <h6>{props.deliveryMethod.name}</h6>
                        <h6 className="ml-auto">{setupContext.currencySymbol} {props.cartItem.deliveryDetails.chargeForDelivery ? props.deliveryMethod.cost.toFixed(2) : "0.00"}</h6>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

VoucherCartItem.propTypes = {
    cartItem: PropTypes.object.isRequired,
    updateCartItemQuantity: PropTypes.func.isRequired,
    removeOrderItem: PropTypes.func.isRequired
};

export default VoucherCartItem;
