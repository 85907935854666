import React from 'react';
import PropTypes from 'prop-types';

function TextWithIcon({ icon, text, iconClass, textClass, className }) {
    return (
        <div className={className ? 'd-flex text-with-icon ' + className : 'd-flex text-with-icon'}>
            <div className={iconClass ? 'icon ' + iconClass : 'icon'}>{icon}</div>
            <div className={textClass ? 'text ' + textClass : 'text'} data-testid="TextWithIcon-text">
                {text}
            </div>
        </div>
    );
}

TextWithIcon.propTypes = {
    icon: PropTypes.object.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    iconClass: PropTypes.string,
    textClass: PropTypes.string
};

export default TextWithIcon;
