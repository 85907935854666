import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const ButtonWrapper = ({ className, text, disabled, onClick }) => (
  <Button className={className + " btn"} disabled={disabled} onClick={onClick}>{text} </Button>
);

ButtonWrapper.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func
};

ButtonWrapper.defaultProps = {
  text: 'Submit!',
};

export default ButtonWrapper;