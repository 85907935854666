import deliveryMethod from '../enums/deliveryMethod'

class CartHelper {

    static findMatchingItemInCart(cartItems, currentItem, excludeCurrentSendingOption) {
        if (cartItems.some(v => v.voucherId === currentItem.voucherId)) {
            let sameVoucherTypeAndDeliveryMethod;
            if (excludeCurrentSendingOption) {
                sameVoucherTypeAndDeliveryMethod = cartItems.filter(
                    v => v.voucherId === currentItem.voucherId && v.deliveryMethod === currentItem.deliveryMethod && v.sendingOption !== currentItem.sendingOption);
            } else {
                sameVoucherTypeAndDeliveryMethod = cartItems.filter(
                    v => v.voucherId === currentItem.voucherId && v.deliveryMethod === currentItem.deliveryMethod);
            }
            if (currentItem.deliveryMethod === deliveryMethod.email) {
                return sameVoucherTypeAndDeliveryMethod.find(voucher => {
                    return JSON.stringify(currentItem.recipientDetails).toUpperCase().replace(/\s+/g, '') ===
                        JSON.stringify(voucher.recipientDetails).toUpperCase().replace(/\s+/g, '');
                });
            } else {
                return sameVoucherTypeAndDeliveryMethod.find(voucher => {
                    return (JSON.stringify(currentItem.recipientDetails).toUpperCase().replace(/\s+/g, '') ===
                        JSON.stringify(voucher.recipientDetails).toUpperCase().replace(/\s+/g, '')) &&
                        (JSON.stringify(currentItem.deliveryDetails).toUpperCase().replace(/\s+/g, '') ===
                        JSON.stringify(voucher.deliveryDetails).toUpperCase().replace(/\s+/g, ''));
                });
            }
        }
        return null;
    }
}

export default CartHelper;