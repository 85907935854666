import React from 'react';
import { Row, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { ReactComponent as CartIcon }  from '../content/icons/Cart.svg';
import { ReactComponent as ChevronRightIcon } from '../content/icons/chevron-right.svg';
import sendingOption from '../enums/sendingOption';
import deliveryMethod from '../enums/deliveryMethod';

const Footer = (props) => {
    const { intl } = props;

    Footer.propTypes = {
        totalVouchers: PropTypes.number.isRequired,
        totalCost: PropTypes.string.isRequired,
    };

    function getFooterRowClasses() {
        const classes = ["fixed-bottom"]
        if (props.isMobileView) {
            classes.push("voucher-footer-mobile-row");
        } else {
            classes.push("voucher-footer-row");
        }

        if (!props.showActionButtons) {
            classes.push("no-buttons");
        }

        if (props.totalVouchers === 0) {
            classes.push("no-cart");
        }

        return classes.join(" ");
    }

    function isRecipientInformationInComplete() {
        const noSendingOptionOrQuantitySelected = props.selectedVoucher.selectedQuantity === 0 || props.selectedVoucher.sendingOption === null;
        if (props.selectedVoucher.deliveryMethod === null) {
            return true;
        } else {
            if (props.selectedVoucher.deliveryMethod === deliveryMethod.email) {
                if (!props.hasDeliveryMethodSetup && props.selectedVoucher.sendingOption === sendingOption.sendToMe) {
                    return noSendingOptionOrQuantitySelected;
                } else {
                    return noSendingOptionOrQuantitySelected ||
                        !props.selectedVoucher.recipientDetails.firstName ||
                        !props.selectedVoucher.recipientDetails.lastName ||
                        !props.selectedVoucher.recipientDetails.email ||
                        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(props.selectedVoucher.recipientDetails.email);
                }
            }
            if (props.selectedVoucher.deliveryMethod === deliveryMethod.delivery) {
                return noSendingOptionOrQuantitySelected ||
                    !props.selectedVoucher.recipientDetails.firstName ||
                    !props.selectedVoucher.recipientDetails.lastName ||
                    !props.selectedVoucher.deliveryDetails.deliveryOption ||
                    !props.selectedVoucher.deliveryDetails.house ||
                    !props.selectedVoucher.deliveryDetails.street ||
                    !props.selectedVoucher.deliveryDetails.town ||
                    !props.selectedVoucher.deliveryDetails.postcode;
            }
        }
    }

    function isAddToCartButtonDisabled() {
        const itemInCartAlready = props.itemsInCart.some(ci => ci.voucherId === props.selectedVoucher.id);
        return isRecipientInformationInComplete() || itemInCartAlready;
    }

    function isBuyNowButtonDisabled() {
        return isRecipientInformationInComplete();
    }


    return (
        <Row className={getFooterRowClasses()}>
            <div className={props.isMobileView ? 'container' : 'container d-flex'}>
                {props.totalVouchers > 0 &&
                    <div className='total-price-wrapper d-flex align-items-center' onClick={() => props.handleGoToCheckout()}>
                        <div className="rd-font-size-large total-cart-quantity">
                            <FormattedMessage id={"Common.ItemsInCart"} values={{ totalVouchers: props.totalVouchers }} />
                        </div>
                        <div className="rd-font-size-large rd-font-weight-medium total-cart-price">
                            <FormattedMessage id={"Common.Total"} />: {props.totalCost}
                        </div>
                        <ChevronRightIcon/>
                    </div>
                }
                {props.showActionButtons &&
                    <div className="d-flex align-items-center footer-action-buttons">
                    <Button className="rd-button-outline add-to-cart-button" onClick={() => props.addToCart()} disabled={isAddToCartButtonDisabled()}>
                            <CartIcon /> {intl.formatMessage({ id: 'Vouchers.AddToCart' })}
                        </Button>{' '}
                    <Button className="rd-button ml-auto" onClick={() => props.handleBuyNowClick()} disabled={isBuyNowButtonDisabled()}>
                            {intl.formatMessage({ id: 'Common.BuyNow' })}
                        </Button>{' '}
                    </div>
                }
            </div>
        </Row >
    );
}

export default injectIntl(Footer);