import React from 'react';
import PropTypes from 'prop-types';

function VoucherProcessingMessage({ headerText, bodyText }) {
    return (
<div className="bg-warning voucher-processing-message">
  <div className="text-container">
    <div className="headerText pt-md-4">
      {headerText}
    </div>
    <div className="bodyText pb-md-4">
      <div>{bodyText.mainBodyText}</div>
      <div>{bodyText.secondaryBodyText}</div>
    </div>
  </div>
</div>

    );
}

VoucherProcessingMessage.propTypes = {
    headerText: PropTypes.oneOfType([PropTypes.string]),
    bodyText: PropTypes.shape({
        mainBodyText: PropTypes.string.isRequired,
        secondaryBodyText: PropTypes.string.isRequired,
      })
};

export default VoucherProcessingMessage;
