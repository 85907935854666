import React from "react";
import { Input, FormGroup, FormFeedback } from "reactstrap";
import PropTypes from "prop-types";
import WarningIcon from "../utilities/Icons/SvgIcons/Warning.js"

const InputField = props => {
  const validTypes = ["text", "email", "password", "url", "number", "textarea" ];

  InputField.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(validTypes),
    title: PropTypes.string,
    placeholder: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    invalid: PropTypes.bool,
    valid: PropTypes.bool
  };
  
  const getImageClassName = (props) => {
    if (props.invalid) return "invalidImage";
    else if (props.valid) return "validImage";
    else return "";
  };

  return (
    <FormGroup>
      <Input
        className={"rd-input " + props.className}
        invalid={props.invalid}
        valid={props.valid}
        type={props.type}
        placeholder={props.placeholder}
        disabled={props.disabled}
        value={props.value}
        onChange={props.onChange}
        id={props.id}
        readOnly={props.readOnly}
      />
      <FormFeedback valid={props.valid} invalid={props.invalid}>
        <WarningIcon className={getImageClassName(props)} height="30px" width="30px"/>
        {props.validationMessage}
      </FormFeedback>
    </FormGroup>
  );
};

export default InputField;
