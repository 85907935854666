import React from 'react';
import PropTypes from 'prop-types';
import loadingIcon from '../content/images/rd-bowtie-anim-transp.gif';

function LoadingSpinner({ loadingText, noMargin }) {
    const wrapperClassName = noMargin ? 'rd-spinner-wrapper--no-margin' : 'rd-spinner-wrapper';
    const spinnerClassName = noMargin ? 'rd-spinner--no-margin' : 'rd-spinner';
    return (
        <div className={wrapperClassName}>
            <img className={`${spinnerClassName} fade-in-1s`} alt="spinner" src={loadingIcon} />
            {loadingText && <div className="rd-spinner-text">{loadingText}</div>}
        </div>
    );
}

LoadingSpinner.propTypes = {
    loadingText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    noMargin: PropTypes.bool //if supplied will remove the top margin from the spinner
};

export default LoadingSpinner;
