import React, { useState, Fragment, useContext, useEffect } from 'react';
import PropTypes from 'prop-types'
import VoucherPreviewCard from './VoucherPreviewCard';
import { injectIntl, FormattedMessage } from 'react-intl';
import Footer from './Footer';
import LoadingSpinner from './LoadingSpinner';
import useScreenSize from '../hooks/useScreenSize';
import sendingOption from '../enums/sendingOption';
import deliveryMethod from '../enums/deliveryMethod';
import recipientDetailsFields from '../enums/recipientDetailsFields';
import deliveryDetailsFields from '../enums/deliveryDetailsFields';
import infoBarType from '../enums/infoBarType';
import SegmentHelper from './../helpers/SegmentHelper'
import InputField from './InputField';
import { CustomInput, Label } from 'reactstrap';
import NumericInputField from './NumericInputField'
import ValidationInput from './ValidationInput'
import ValidationMessage from '../components/ValidationMessage';
import Dropdown from './Dropdown'
import SetupContext from './../contexts/SetupContext';
import Voucher from '../domainObjects/Voucher';
import DeliveryDetails from '../domainObjects/DeliveryDetails';
import CartHelper from '../helpers/CartHelper';

let _ID = 0;

const ChooseVouchersPage = (props) => {
    const { intl } = props;
    const setupContext = useContext(SetupContext);

    ChooseVouchersPage.propTypes = {
        microsite: PropTypes.string.isRequired
    };


    const { isTabletView } = useScreenSize();
    const [infoBars, setInfoBars] = useState([]);
    const [recipientCharacterLimit, setRecipientCharacterLimit] = useState(false);
    const [selectedVoucher, setSelectedVoucher] = useState(null);
    const [hasDeliveryMethodSetup, setHasDeliveryMethodSetup] = useState(false);
    const [hasEmailDeliveryMethodSetup, setHasEmailDeliveryMethodSetup] = useState(false);

    const addSuccessBar = message => {
        setInfoBars([...infoBars, { id: _ID++, type: infoBarType.success, message: message }]);
    };

    useEffect(() => {
        if (!setupContext.restaurantName && props.microsite) {
            props.getSetupContext(props.microsite);
        }
    }, [setupContext, props.microsite]);

    useEffect(() => {
        if (setupContext.restaurantName && props.microsite) {
            window.analytics.page("VoucherPurchase Page");
        }
    }, [setupContext, props.microsite]);

    useEffect(() => {
        if (setupContext.postageMethods) {
            setHasDeliveryMethodSetup(setupContext.postageMethods.some(pm => pm.requiresDeliveryAddress));
            setHasEmailDeliveryMethodSetup(setupContext.postageMethods.some(pm => pm.requiresDeliveryAddress === false));
        }
    }, [setupContext]);
    
    useEffect(() => {
        if (setupContext.restaurantName && !setupContext.sellsVouchers && !props.isLoading) {
            setupContext.addErrorBar(intl.formatMessage({ id: 'Common.VouchersNotEnabled' }));
        }
    }, [setupContext.sellsVouchers, props.isLoading]);

    function toggleSelectedVoucher(voucher) {
        if (selectedVoucher && voucher.voucherId === selectedVoucher.voucherId) {
            setSelectedVoucher(null);
        } else {
            const v = new Voucher(voucher);
            if (hasDeliveryMethodSetup && !hasEmailDeliveryMethodSetup) {
                v.deliveryMethod = deliveryMethod.delivery;
            }
            if (hasEmailDeliveryMethodSetup && !hasDeliveryMethodSetup) {
                v.deliveryMethod = deliveryMethod.email;
                v.deliveryDetails.deliveryOption = setupContext.postageMethods.find(pm => pm.requiresDeliveryAddress === false).postageId;
            }
            setSelectedVoucher(v);

        }
        SegmentHelper.trackClickWithProperties("Web Voucher Selection",
            {
                Voucher_name: voucher.name,
                Voucher_cost: voucher.cost
            });
    }

    function updateVoucherSendingOption(sendingOption) {
        const selectedVoucherCopy = { ...selectedVoucher }
        selectedVoucherCopy.sendingOption = sendingOption;
        setSelectedVoucher(selectedVoucherCopy);
    }

    function updateDeliveryMethod(selectedDeliveryMethod) {
        const selectedVoucherCopy = { ...selectedVoucher }
        selectedVoucherCopy.deliveryDetails = new DeliveryDetails();
        selectedVoucherCopy.deliveryMethod = selectedDeliveryMethod;
        if (selectedDeliveryMethod === deliveryMethod.email) {
            const emailDeliveryMethod =
                setupContext.postageMethods.find(pm => pm.requiresDeliveryAddress === false).postageId;
            selectedVoucherCopy.deliveryDetails.deliveryOption = emailDeliveryMethod;
        }
        setSelectedVoucher(selectedVoucherCopy);
    }

    function updateRecipientDetailsFields(fieldValue, fieldName) {
        const selectedVoucherCopy = { ...selectedVoucher }
        selectedVoucher.recipientDetails[fieldName] = fieldValue;
        setSelectedVoucher(selectedVoucherCopy);
    }

    function updateRecipientMessage(fieldValue, fieldName) {
        const recipientMaxLength = 2000;
        if (fieldValue.length < recipientMaxLength) {
            setRecipientCharacterLimit(false);
            updateRecipientDetailsFields(fieldValue, fieldName);
        }
        else {
            setRecipientCharacterLimit(true);
        }
    }

    function updateDeliveryDetailsFields(fieldValue, fieldName) {
        const selectedVoucherCopy = { ...selectedVoucher }
        selectedVoucher.deliveryDetails[fieldName] = fieldValue;
        setSelectedVoucher(selectedVoucherCopy);
    }

    function addToCart() {
        const existingItem = CartHelper.findMatchingItemInCart(props.orderItems, selectedVoucher);
        if (!existingItem) {
            props.addOrderItemToCart({
                voucherId: selectedVoucher.voucherId,
                quantity: selectedVoucher.selectedQuantity,
                minimumQuantity: selectedVoucher.minimumQuantity,
                maximumQuantity: selectedVoucher.maximumQuantity,
                unitCost: selectedVoucher.cost,
                name: selectedVoucher.name,
                monthsValid: selectedVoucher.monthsValid,
                recipientDetails: selectedVoucher.recipientDetails,
                deliveryDetails: selectedVoucher.deliveryDetails,
                deliveryMethod: selectedVoucher.deliveryMethod,
                sendingOption: selectedVoucher.sendingOption,
                references: selectedVoucher.references,
                termsAndConditions: selectedVoucher.termsAndConditions,
                uniqueIdentifier: selectedVoucher.uniqueIdentifier
            });
            setSelectedVoucher(null);
        } else {
            if (existingItem.maximumQuantity >= existingItem.quantity + selectedVoucher.selectedQuantity) {
                existingItem.quantity = existingItem.quantity + selectedVoucher.selectedQuantity;
                props.updateOrderItem(existingItem);
            }
            setSelectedVoucher(null);
        }
    }

    function quantityChangedHandler(quantity) {
        if (quantity < selectedVoucher.minimumQuantity) {
                return;
        }

        const selectedVoucherCopy = { ...selectedVoucher }
        selectedVoucherCopy.selectedQuantity = quantity;
        setSelectedVoucher(selectedVoucherCopy);
    }

    function getVoucherRows() {
      if (setupContext && setupContext.vouchers) {
        return setupContext.vouchers.map(v =>
                <VoucherPreviewCard
                    key={v.id}
                    voucher={v}
                    currencySymbol={setupContext.currencySymbol}
                    voucherCost={v.cost}s
                    voucherTitle={v.name}
                    voucherId={v.id}
                    monthsValid={v.monthsValid}
                    onClick={toggleSelectedVoucher}
                    selected={selectedVoucher && selectedVoucher.voucherId === v.voucherId}
                />
            );
        }
        return null;
    }

    function handleGoToCheckout() {
        SegmentHelper.sendButtonClicked("Go to checkout");
        props.history.push(`/${props.microsite}/Details`);
    }


    function handleBuyNowClick() {
        SegmentHelper.sendButtonClicked("Buy Now");
        props.buyOrderItemNow({
            voucherId: selectedVoucher.voucherId,
            quantity: selectedVoucher.selectedQuantity,
            minimumQuantity: selectedVoucher.minimumQuantity,
            maximumQuantity: selectedVoucher.maximumQuantity,
            unitCost: selectedVoucher.cost,
            name: selectedVoucher.name,
            monthsValid: selectedVoucher.monthsValid,
            recipientDetails: selectedVoucher.recipientDetails,
            deliveryDetails: selectedVoucher.deliveryDetails,
            deliveryMethod: selectedVoucher.deliveryMethod,
            sendingOption: selectedVoucher.sendingOption,
            references: selectedVoucher.references,
            termsAndConditions: selectedVoucher.termsAndConditions,
            uniqueIdentifier: selectedVoucher.uniqueIdentifier
        },() => { props.history.push(`/${props.microsite}/Details`) });
    }

    function getTotalItems() {
        let totalItem = 0;
        props.orderItems.forEach(item => {
            totalItem = totalItem + item.quantity;
        });
        return totalItem;
    }

    function deliveryEmailHasError() {
        return !selectedVoucher.recipientDetails.email ||
            !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(selectedVoucher.recipientDetails.email);
    }

    if (props.isLoading ) {
        return <LoadingSpinner loadingText={intl.formatMessage({ id: 'Common.LoadingVouchers' })} />;
    }

    function getDeliveryOptions() {
        return setupContext.postageMethods.filter(pm => pm.requiresDeliveryAddress).map((pm) => ({ text: `${pm.name} - ${setupContext.currencySymbol}${pm.cost.toFixed(2)}`, value: pm.postageId}));
    }

    return (
        <Fragment>
            {setupContext.sellsVouchers && (
                <Fragment>
                <div className="clickable-vouchers-container">
                    <h5> <FormattedMessage id="Vouchers.SelectAVoucher" /></h5>
                    <div className="d-flex vouchers-list">
                        {getVoucherRows()}
                    </div>
                </div>
                {selectedVoucher && (
                    <Fragment>
                        <div className="selected-voucher-information">
                            <h5 className="voucher-name">{selectedVoucher.name}</h5>
                            <h5>{selectedVoucher.description}</h5>
                            <div className="validity">
                                <span className="bold pr-1"><FormattedMessage id="Vouchers.Validity" />:</span>
                                <FormattedMessage
                                    id={selectedVoucher.monthsValid === 0 ? "Vouchers.ValidityMessageNoExpiry" : selectedVoucher.monthsValid === 1 ? "Vouchers.ValidityMessageSingleMonth" : "Vouchers.ValidityMessageMultipleMonths"}
                                    values={{
                                        numberOfMonths: selectedVoucher.monthsValid,
                                    }} />
                            </div>
                            {selectedVoucher.termsAndConditions &&
                                <div className="terms">
                                    <div className="bold">
                                        <FormattedMessage id="Vouchers.Terms" />:
                                        </div>
                                        {selectedVoucher.termsAndConditions}
                                </div>
                            }
                        </div>
                            <div className={isTabletView ? "voucher-purchase-info-wrapper mobile" : "voucher-purchase-info-wrapper"}>
                        <div className="quantity-information">
                            <div className="d-flex quantity-selector-wrapper">
                                <h5 className="quantity"><FormattedMessage id="Vouchers.Quantity" /></h5>
                                { selectedVoucher.maximumQuantity != 1000 && //this is the arbitrary value we chose for no maximum
                                    <span className="max"><FormattedMessage id="Vouchers.MaxQuantity" values={{ maximumQuantity: selectedVoucher.maximumQuantity }} /></span>
                                }
                                <div className="quantity-selector ml-auto">
                                <NumericInputField value={selectedVoucher.selectedQuantity} max={selectedVoucher.maximumQuantity} onChangeHandler={quantityChangedHandler} />
                                </div>
                            </div>
                        </div>
                            <div className="sending-options">
                                <FormattedMessage id="Vouchers.SendingOptions" tagName="h5" />
                            <div className={hasDeliveryMethodSetup && selectedVoucher.sendingOption ? "radio-button-group sending-options-radio-buttons include-border" : "radio-button-group" }>
                                    <CustomInput
                                        checked={
                                        selectedVoucher.sendingOption === sendingOption.sendToMe
                                        }
                                        onChange={() =>
                                        updateVoucherSendingOption(sendingOption.sendToMe)
                                        }
                                        type="radio"
                                        id={'radio-sendToMe'}
                                        name={'customRadio-sendToMe'}
                                        label={intl.formatMessage({ id: 'Vouchers.SendToMe' })}
                                    />
                                    <CustomInput
                                        checked={
                                        selectedVoucher.sendingOption === sendingOption.sendToSomeoneElse
                                        }
                                        onChange={() =>
                                        updateVoucherSendingOption(sendingOption.sendToSomeoneElse)
                                        }
                                        type="radio"
                                        id={'radio-sendToSomeoneElse'}
                                        name={'customRadio-sendToSomeoneElse'}
                                        label={intl.formatMessage({ id: 'Vouchers.SendToSomeoneElse' })}
                                        className={!isTabletView ? "ml-auto" : ""}
                                    />
                                </div>
                        </div>
                        {(selectedVoucher.sendingOption === sendingOption.sendToSomeoneElse || (hasDeliveryMethodSetup && selectedVoucher.sendingOption)) &&
                        <div className={isTabletView ? "delivery-recipient-wrapper-mobile" : "d-flex"}>
                            <div className="recipient-details">
                                <FormattedMessage id={selectedVoucher.sendingOption === sendingOption.sendToMe ? "Vouchers.MyDetails" : "Vouchers.RecipientsDetails"} tagName="h5" />
                                <Label for="firstName"><FormattedMessage id={"Vouchers.RecipientFirstName"} /></Label>
                                <ValidationInput
                                    type="text"
                                    name="firstName"
                                    value={selectedVoucher.recipientDetails.firstName}
                                    onChange={(e) => updateRecipientDetailsFields(e, recipientDetailsFields.firstName)}
                                    hasError={!selectedVoucher.recipientDetails.firstName}
                                    errorMessage={intl.formatMessage({ id: 'Vouchers.FieldNameRequired' },
                                        {
                                            fieldName: intl.formatMessage({ id: 'Vouchers.RecipientFirstName' })

                                        }
                                    )}
                                />
                                <Label for="secondName"><FormattedMessage id={"Vouchers.RecipientLastName"} /></Label>
                                <ValidationInput
                                    type="text"
                                    name="firstName"
                                    value={selectedVoucher.recipientDetails.lastName}
                                    onChange={(e) => updateRecipientDetailsFields(e, recipientDetailsFields.lastName)}
                                    hasError={!selectedVoucher.recipientDetails.lastName}
                                    errorMessage={intl.formatMessage({ id: 'Vouchers.FieldNameRequired' },
                                                {
                                                    fieldName: intl.formatMessage({ id: 'Vouchers.RecipientLastName' })

                                                }
                                            )}
                                />
                                {hasDeliveryMethodSetup && hasEmailDeliveryMethodSetup &&
                                    <div className="radio-button-group">
                                        <CustomInput
                                            checked={selectedVoucher.deliveryMethod === deliveryMethod.email}
                                            onChange={() => updateDeliveryMethod(deliveryMethod.email)}
                                            type="radio"
                                            id={'radio-sendViaEmail'}
                                            name={'customRadio-sendViaEmail'}
                                            label={intl.formatMessage({ id: 'Vouchers.SendViaEmail' })}
                                        />
                                        <CustomInput
                                            checked={selectedVoucher.deliveryMethod === deliveryMethod.delivery}
                                            onChange={() => updateDeliveryMethod(deliveryMethod.delivery)}
                                            type="radio"
                                            id={'radio-delivery'}
                                            name={'customRadio-delivery'}
                                            label={intl.formatMessage({ id: 'Vouchers.Delivery' })}
                                            className={"ml-5"}
                                        />
                                    </div>
                                }
                                {selectedVoucher.deliveryMethod === deliveryMethod.email &&
                                    <Fragment>
                                        <Label for="email"><FormattedMessage id={"Vouchers.RecipientsEmail"} /></Label>
                                        <ValidationInput
                                            type="email"
                                            name="firstName"
                                            value={selectedVoucher.recipientDetails.email}
                                            onChange={(e) => updateRecipientDetailsFields(e, recipientDetailsFields.email)}
                                            hasError={deliveryEmailHasError()}
                                            errorMessage={intl.formatMessage({ id: 'Common.ValidEmailRequired' })}
                                        />
                                    </Fragment>
                                }
                                {selectedVoucher.sendingOption === sendingOption.sendToSomeoneElse &&
                                    <Fragment>
                                        <Label for="email"><FormattedMessage id={"Vouchers.RecipientMessage"} /></Label>
                                        <InputField
                                            type="textarea"
                                            value={selectedVoucher.recipientDetails.message}
                                                onChange={(e) => updateRecipientMessage(e.target.value, recipientDetailsFields.message)}
                                            />
                                            {recipientCharacterLimit &&
                                                <ValidationMessage message={intl.formatMessage({ id: 'Vouchers.VoucherMessageLengthReached' })} />
                                            }

                                    </Fragment>
                                        }
                            </div>
                            {selectedVoucher.deliveryMethod === deliveryMethod.delivery &&
                                <div className="delivery-details">
                                    <FormattedMessage id="Vouchers.Delivery" tagName="h5" />
                                    <Label><FormattedMessage id={"Vouchers.DeliveryOption"} /></Label>
                                    <Dropdown
                                        onValueChange={(value) => updateDeliveryDetailsFields(value, deliveryDetailsFields.deliveryOption)}
                                        options={getDeliveryOptions()}  
                                        defaultValue={intl.formatMessage({ id: 'Vouchers.SelectDeliveryOption' })}
                                        selectedValue={selectedVoucher.deliveryDetails.deliveryOption}
                                        hideRemoveItemIcon
                                        hasError={!selectedVoucher.deliveryDetails.deliveryOption}
                                        errorMessage={intl.formatMessage({ id: 'Vouchers.FieldNameRequired' },
                                                {
                                                    fieldName: intl.formatMessage({ id: 'Vouchers.DeliveryOption' })

                                                }
                                            )}
                                        />
                                    <Label><FormattedMessage id={"Vouchers.HouseNumber"} /></Label>
                                    <ValidationInput
                                        type="text"
                                        value={selectedVoucher.deliveryDetails.house}
                                        onChange={(e) => updateDeliveryDetailsFields(e, deliveryDetailsFields.house)}
                                        hasError={!selectedVoucher.deliveryDetails.house}
                                        errorMessage={intl.formatMessage({ id: 'Vouchers.FieldNameRequired' },
                                                {
                                                    fieldName: intl.formatMessage({ id: 'Vouchers.HouseNumber' })

                                                }
                                            )}
                                    />
                                    <Label><FormattedMessage id={"Vouchers.Street"} /></Label>
                                    <ValidationInput
                                        type="text"
                                        value={selectedVoucher.deliveryDetails.street}
                                        onChange={(e) => updateDeliveryDetailsFields(e, deliveryDetailsFields.street)}
                                        hasError={!selectedVoucher.deliveryDetails.street}
                                        errorMessage={intl.formatMessage({ id: 'Vouchers.FieldNameRequired' },
                                                {
                                                    fieldName: intl.formatMessage({ id: 'Vouchers.Street' })

                                                }
                                            )}
                                    />                                    
                                    <Label><FormattedMessage id={"Vouchers.Town"} /></Label>
                                    <ValidationInput
                                        type="text"
                                        value={selectedVoucher.deliveryDetails.town}
                                        onChange={(e) => updateDeliveryDetailsFields(e, deliveryDetailsFields.town)}
                                        hasError={!selectedVoucher.deliveryDetails.town}
                                        errorMessage={intl.formatMessage({ id: 'Vouchers.FieldNameRequired' },
                                                {
                                                    fieldName: intl.formatMessage({ id: 'Vouchers.Town' })

                                                }
                                            )}
                                    />
                                    <Label><FormattedMessage id={"Vouchers.Postcode"} /></Label>
                                    <ValidationInput
                                        type="text"
                                        value={selectedVoucher.deliveryDetails.postcode}
                                        onChange={(e) => updateDeliveryDetailsFields(e, deliveryDetailsFields.postcode)}
                                        hasError={!selectedVoucher.deliveryDetails.postcode}
                                        errorMessage={intl.formatMessage({ id: 'Vouchers.FieldNameRequired' },
                                                {
                                                    fieldName: intl.formatMessage({ id: 'Vouchers.Postcode' })

                                                }
                                            )}
                                    />
                                    </div>
                                }
                            </div>
                        }
                        </div>
                    </Fragment>
                )}
                {(selectedVoucher != null || props.orderItems.length > 0) &&
                    <Footer isMobileView={isTabletView}
                        totalVouchers={getTotalItems()}
                        totalCost={setupContext.currencySymbol + props.totalCost}
                        handleGoToCheckout={handleGoToCheckout}
                        handleBuyNowClick={handleBuyNowClick}
                        selectedVoucher={selectedVoucher}
                        addToCart={addToCart}
                        showActionButtons={selectedVoucher != null}
                        itemsInCart={props.orderItems}
                        hasDeliveryMethodSetup={hasDeliveryMethodSetup}
                    />
                }
                </Fragment>
            
            )}
        </Fragment>
    );
}

export default injectIntl(ChooseVouchersPage);