import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input, InputGroup, InputGroupAddon, DropdownMenu, DropdownItem } from 'reactstrap';
import { ReactComponent as CloseIcon } from '../content/icons/close.svg';

class DropdownMenuWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.getDropdownOptions = this.getDropdownOptions.bind(this);
        this.getFilteredOptions = this.getFilteredOptions.bind(this);
    }

    getDropdownEntry(option) {
        const isCurrentValue = this.props.selectedValue === option.value;
        return (
            <Fragment key={option.value}>
                <div className="dropdown-item-wrapper">
                    <DropdownItem
                        className={isCurrentValue ? 'dropdown-item-selected' : ''}
                        onClick={() => {
                            if (!isCurrentValue) this.props.onValueChange(option.value);
                        }}
                    >
                        {option.text}
                    </DropdownItem>
                    {isCurrentValue && this.props.hasDefaultValue && !this.props.hideRemoveItemIcon && (
                        <div>
                            <CloseIcon onClick={e => this.removeSelectedValue(e, option.value)} />
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }

    getDropdownOptions() {
        // Prioritise optionGroups if both those and options have been passed as props
        if (this.props.optionGroups) {
            const optionGroupsWithOptions = this.props.optionGroups.filter(g => g.options.length > 0);
            return optionGroupsWithOptions.map((optionGroup, index) => {
                return (
                    <Fragment key={index}>
                        <div onClick={e => e.stopPropagation()}>
                            <DropdownItem header>{optionGroup.label}</DropdownItem>
                        </div>
                        {this.getFilteredOptions(optionGroup.options)}
                    </Fragment>
                );
            });
        } else if (this.props.options) {
            return this.getFilteredOptions(this.props.options);
        } else {
            return null;
        }
    }

    getFilteredOptions(options) {
        // Filter results if required, if not just return the options as usual
        return this.props.searchTerm !== '' && this.props.isSearchable
            ? options.reduce((filtered, option) => {
                if (option.text.toLowerCase().indexOf(this.props.searchTerm) > -1) {
                    filtered.push(this.getDropdownEntry(option));
                }
                return filtered;
            }, [])
            : options.map(option => this.getDropdownEntry(option));
    }

    removeSelectedValue(e, value) {
        // Prevent further event bubbling
        e.stopPropagation();
        this.props.onValueChange(value);
    }

    render() {
        // Include search bar in the dropdown menu or not
        const dropdownOptions = this.props.isSearchable
            ? [
                <InputGroup key={1}>
                    <InputGroupAddon addonType="prepend">
                        <CloseIcon />
                    </InputGroupAddon>
                    <Input
                        autoFocus
                        type="text"
                        onBlur={this.props.endEdit}
                        onClick={e => {
                            e.stopPropagation();
                        }}
                        placeholder={'Type to search'}
                        onChange={e => this.props.filterSearch(e.target.value)}
                    />
                </InputGroup>
            ]
            : [];
        dropdownOptions.push(this.getDropdownOptions());
        return (
            <DropdownMenu right flip={this.props.flip} className={this.props.className}>
                {dropdownOptions}
            </DropdownMenu>
        );
    }
}

DropdownMenuWrapper.propTypes = {
    endEdit: PropTypes.func,
    filterSearch: PropTypes.func,
    hasDefaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    isSearchable: PropTypes.bool,
    flip: PropTypes.bool,
    options: PropTypes.array,
    optionGroups: PropTypes.array,
    searchTerm: PropTypes.string,
    className: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hideRemoveItemIcon: PropTypes.bool
};

DropdownMenuWrapper.defaultProps = {
    flip: true
};

export default DropdownMenuWrapper;
