import ApiHelper from '../helpers/ApiHelper';

class ErrorService {
    static reportErrorFromReact(message, errorStack, componentStack) {
        return ApiHelper.postJSON('/api/logging/JavascriptError/', {
            Message: JSON.stringify(message),
            ErrorStack: JSON.stringify(errorStack),
            ComponentStack: JSON.stringify(componentStack)
        });
    }

    static reportErrorFromRequest(message) {
        return ApiHelper.postJSON('/api/logging/RequestError/', {
            Message: JSON.stringify(message)
        });
    }
}

export default ErrorService;