import React from 'react';
import PropTypes from 'prop-types';

const Header = (props) => {

    Header.propTypes = {
        logoUrl: PropTypes.string
    };

    return (
        <div className="voucher-header-row">
        </div>
    );
}

export default Header;