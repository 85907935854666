class SegmentHelper {

    static sendPageLoad(location) {
        window.analytics.track('Page Loaded',
            {
                location: location
            });
    }

    static sendButtonClicked(buttonName) {
        window.analytics.track('Button Clicked',
            {
                buttonName: buttonName
            });
    }

    static sendCartEvent(cartEvent) {
        window.analytics.track('Cart Updated',
            {
                cartEvent: cartEvent
            });
    }

    static trackClickWithProperties(event, properties) {
        window.analytics.track(event, properties);
    }
}

export default SegmentHelper;