import ApiHelper from '../helpers/ApiHelper';

class VoucherService {

    static getVouchersSetup(micrositeName) {
        return ApiHelper.get(
            `api/voucher/${micrositeName}/GetVouchersSetup`
        ).then(response => response.data);
    }

    static generateVoucherCheckoutSession(micrositeName, voucherCheckoutRequestDto) {
        return ApiHelper.postJSON(
            `api/voucher/${micrositeName}/GenerateCheckoutSessionId`,
            JSON.stringify(voucherCheckoutRequestDto)
        ).then(response => response.data);
    }

    static createVoucherOrder(micrositeName, voucherOrderRequestDto) {
        return ApiHelper.postJSON(
            `api/voucher/${micrositeName}/CreateVoucherOrder`,
            JSON.stringify(voucherOrderRequestDto)
        ).then(response => response.data);
    }
}

export default VoucherService;