import React from 'react';

export default React.createContext({
    restaurantName: "",
    postageMethods: [],
    currencySymbol: "",
    logoUrl: "",
    stripePublishableKey: "",
    stripeAccountId: 0,
    requiresPurchaserFullName: "",
    termsAndConditions: "",
    providerAddress: "",
    providerEmailAddress: "",
    providerPhoneNumber: "",
    providerCountryCode: "",
    vouchers: [],
    sellsVouchers: false
});