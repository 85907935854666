import React, { useContext, Fragment } from 'react';
import { Container } from 'reactstrap';
import Interceptor from '../interceptor'; // eslint-disable-line no-unused-vars
import Header from './Header';
import useScreenSize from '../hooks/useScreenSize';
import SetupContext from './../contexts/SetupContext';
import TextWithIcon from './TextWithIcon';
import { ReactComponent as WarningIcon } from '../content/icons/Icon-Alert.svg';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types'

function Layout(props) {
    const { intl } = props;
    const { isTabletView } = useScreenSize();
    const setupContext = useContext(SetupContext);

    function getAddress() {
        const addressArray = [];
        if (setupContext.providerAddress.addressLine) {
            addressArray.push(setupContext.providerAddress.addressLine);
        }
        if (setupContext.providerAddress.town) {
            addressArray.push(setupContext.providerAddress.town);
        }
        if (setupContext.providerAddress.country) {
            addressArray.push(setupContext.providerAddress.country);
        }
        if (setupContext.providerAddress.postcode) {
            addressArray.push(setupContext.providerAddress.postcode);
        }
        return addressArray.join(', ');
    }

    return (
        <Container fluid className="body">
            {setupContext && setupContext.logoUrl && setupContext.restaurantName && setupContext.providerAddress &&
                <Fragment>
                    {setupContext.displayPreviewHeader && (
                        <div className="vouchers-preview-header"><TextWithIcon icon={<WarningIcon />} text={intl.formatMessage({ id: 'Vouchers.VoucherPreviewHeading' })} /></div>
                    )}
                    <Header isMobileView={isTabletView} logoUrl={setupContext.logoUrl} />
                    <div className="content-background">
                        <div className={isTabletView ? "vouchers-main-body container mobile" : "vouchers-main-body container"}>
                            <div className="restaurant-info">
                                    {setupContext.logoUrl &&
                                    <img src={setupContext.logoUrl} width="100px" height="100px" alt="Logo" />
                                }
                                { setupContext.restaurantName &&
                                    setupContext.providerAddress &&
                                    <div className="d-flex flex-column restaurant-address">
                                    <h2>{setupContext.restaurantName}</h2>
                                        <div className="provider-address">{getAddress()}</div>
                                    </div>
                                }
                            </div>
                            {props.children}
                        </div>
                    </div> 
                </Fragment>
            }
            {!(setupContext && setupContext.logoUrl && setupContext.restaurantName && setupContext.providerAddress) &&
                <Fragment>
                    {props.children}
                </Fragment>
            }
        </Container>);
}

Layout.propTypes = {
    displayPreviewHeader: PropTypes.bool
};

export default injectIntl(Layout);
