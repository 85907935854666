import axios from 'axios';
import ErrorService from './services/ErrorService';

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response) {
            ErrorService.reportErrorFromRequest(error);
        }
        return Promise.reject(error);
    }
);