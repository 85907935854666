import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import TextWithIcon from './TextWithIcon';
import { ReactComponent as ClockIcon } from '../content/icons/Icon-Clock.svg';
import { ReactComponent as SuccessIcon } from '../content/icons/Icon-Success.svg';

function VoucherPreviewCard(props) {
    const { intl } = props;

    function getExpiryText() {
        if (props.monthsValid === 0) {
           return intl.formatMessage({ id: 'Vouchers.VoucherExpiryNone' });
        }
        return props.monthsValid === 1
            ? intl.formatMessage({ id: 'Vouchers.VoucherExpiryMonth' })
            : intl.formatMessage({ id: 'Vouchers.VoucherExpiryMonths' },
                { numberOfMonths: props.monthsValid });
    }

    return (
        <div className={props.selected ? "voucher-review-entry selected" : "voucher-review-entry"} onClick={() => props.onClick(props.voucher)}>
            <div>
                <div className="voucher-header">
                    <div className="voucher-info">
                        <div className="voucher-cost">{`${props.currencySymbol}${props.voucherCost}`}</div>
                        <div className="text-uppercase">{props.voucherTitle}</div>
                    </div>
                    {props.selected &&
                        <div className="progress-icon-container success-icon ml-auto">
                            <SuccessIcon />
                        </div>
                    }
                </div>

                <div className="voucher-expiry">
                    <TextWithIcon
                        text={getExpiryText()}
                        icon={<ClockIcon />}
                        iconClass={'clock-icon'}
                    />
                </div>
            </div>
        </div>
    );
}

VoucherPreviewCard.propTypes = {
    voucher: PropTypes.object.isRequired,
    updateVoucher: PropTypes.func,
    deleteVoucher: PropTypes.func
};

export default injectIntl(VoucherPreviewCard);
