import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { injectIntl } from 'react-intl';

const ResdiaryTermsAndConditions = (props) => {
    const { intl } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <div>
            <Button className="rd-font-size-base d-block link-button" onClick={toggle}>{intl.formatMessage({ id: 'Common.ResDiaryTermsAndConditions' })}</Button>
            <Modal isOpen={modal} toggle={toggle} scrollable={true}>
                <ModalHeader toggle={toggle}>ResDiary Consumer Terms of Use</ModalHeader>
                <ModalBody>
                    <h4>1. Introduction</h4>
                    <p>These ResDiary Terms of Use (this "Agreement") apply to your consumer use of</p>
                    <ol>
                        <li>the ResDiary website at https://www.resdiary.com and all affiliated websites owned and operated by ResDiary along with its affiliated companies (collectively, the "ResDiary Site").</li>
                        <li>the restaurant reservation services made available by ResDiary through the ResDiary Site, any ResDiary-branded application for your mobile or other device (collectively, the "ResDiary Application"), and any other online properties of ResDiary or third parties, as described below (the "Reservation Services"). </li>
                        <li>any ResDiary Application.</li>
                        <li>any other services or features made available by ResDiary through the ResDiary Site or any ResDiary Application.</li>
                    </ol>
                    <p>Together, the items in 1 to 4 inclusive are the "Services".</p>
                    <p>In accessing the Site, you (hereafter referred to as "you" or "the User") agree to abide by the terms and conditions. </p>

                    <h4>2. Acknowledgment and Acceptance of Terms of Service</h4>
                    <p>This Agreement incorporates ResDiary's standard policies, procedures, and terms and conditions for use of the Services that are referenced by name or by links in this Agreement (hereafter referred to collectively, the "ResDiary Policies"). In accessing the Site and using the Services, or by clicking "accept" or "agree" to this Agreement you acknowledge that you have read, understood, and agreed to be bound by this Agreement and that you represent and warrant that you are of legal age and not prohibited by law from accessing and using the Services. </p>
                    <p>ResDiary may update or revise this Agreement (including any ResDiary Policies) from time to time. You agree that you will review this Agreement periodically. </p>
                    <p>You are free to decide whether or not to accept a modified version of this Agreement, but accepting this Agreement, as modified, is required for you to continue using the Services. You may have to click "accept" or "agree" to show your acceptance of any modified version of this Agreement. If you do not agree to the terms of this Agreement or any modified version of this Agreement, your sole recourse is to terminate your use of the Services. </p>
                    <p>Except if otherwise expressly stated by ResDiary, any use of the Services (e.g. the use of the Reservation Services) is subject to the version of this Agreement in effect at the time of use.</p>

                    <h4>3. Reservation Services</h4>
                    <p><strong>Personal Information: </strong></p>
                    <p>Some Services, such as Reservation Services may require the User to supply certain personal information so that the restaurant can identify the reservations and the diner can be contacted via a confirmation email or SMS to confirm reservations. This personal information can include email address and name and telephone number, which will hereafter be referred to as "Registration Information". In consideration for the use of the Services, the User agrees to do the following:
                <ol>
                            <li>Provide true, current, complete and accurate information about the User as prompted to do so by the Services. </li>
                            <li>Maintain and promptly update this information as required to keep it true, current, complete and accurate. </li>
                        </ol>
                    </p>
                    <p>"Registration Information", when you create a profile, will be retained by ResDiary to provide the Services to the User and for any other purpose to which you have consented.  </p>
                    <p>"Personal Information", when you make a reservation, will be retained by the restaurant or third party according to their retention periods. </p>
                    <p>See the <a href="https://sales.resdiary.com/privacy-statement/" target="_blank"><span class="link-text">Privacy Policy</span></a> for more information.</p>

                    <p><strong>Restaurant Reservations: </strong></p>
                    <ol>
                        <li>ResDiary provides the Reservation Services to you for the purpose of assisting you in securing dining reservations at participating third-party restaurants or other venues (each, "a Restaurant"). </li>
                        <li>In response to your online request for a Restaurant reservation through the ResDiary Site or ResDiary Application, ResDiary directly contacts the Restaurant's computerised database of reservations. </li>
                        <li>The availability of reservations is determined at the time of your query. </li>
                        <li>When a reservation is made by you through the ResDiary Site or ResDiary Application, the Restaurant may provide confirmation of the reservation to you by email or SMS via ResDiary systems. </li>
                    </ol>

                    <p><strong>'No-Show' Policy</strong></p>
                    <p>ResDiary is committed to providing superior quality services to Users and Restaurants. Each Restaurant will apply its own policy regarding any 'no-show' or cancellation and ResDiary accepts no responsibility or liability for any such Restaurant policy or its application. </p>
                    <p>The User should check the relevant Restaurant's terms and conditions as well as the terms of any email or other confirmation communications carefully. </p>
                    <p>In order to use the Reservation Services for these Restaurants, you may be asked to provide valid debit or credit card information. In the event that you wish to cancel your reservation you must do so in accordance with the Restaurant's cancellation policy. </p>
                    <p>ResDiary may use debit or credit card information as described in our <a href="https://sales.resdiary.com/privacy-statement/" target="_blank"><span class="link-text">privacy policy</span></a> which will be collected securely via Stripe (our third party payment processor), but ResDiary shall have no liability for any charges made to the debit or credit card account for any failure to cancel your reservation in accordance with any Restaurant's cancellation policy.</p>

                    <p><strong>Usage Guidelines</strong></p>
                    <p>You agree to use the Reservation Services only to book reservations at Restaurants and then honour those reservations by arriving at the Restaurant(s) on time and ordering and paying for meals. You further agree not to book more than one reservation for your personal use during any one meal time. Resale or attempted resale of reservations is prohibited, and is grounds for, among other things, cancellation of your reservations or termination of your access to the Services.</p>

                    <h4>4. Privacy Policy</h4>
                    <p>ResDiary is committed to helping you safeguard your privacy online. Please review our <a href="https://sales.resdiary.com/privacy-statement/" target="_blank"><span class="link-text">privacy policy</span></a> for details about how we collect, use, and disclose information in connection with the Services.</p>

                    <h4>5. Communications from ResDiary</h4>
                    <p><strong>Marketing communications</strong></p>
                    <p>Unless the User has agreed to the use of the Registration Information for direct marketing purposes by ResDiary, third-party restaurants or other venues or selected third parties, we agree to use the Registration Information only for the purpose of supplying the Services to the User and any other purpose to which you have consented. </p>
                    <ol>
                        <li>If you wish to receive marketing from ResDiary, third-party restaurants or other venues or selected third parties, you must opt in. These marketing communications will take the form of email or SMS, and you can opt in or out of each individually. </li>
                        <li>You can unsubscribe from any of our email communications at any time by clicking on the 'Unsubscribe' link that appears in the footer of all our email communications. ResDiary does not make any representation or warranty with respect to the content of any direct marketing messages or any goods or services that may be obtained from such third parties, and the User agrees that ResDiary shall not have any liability with respect thereof.</li>
                        <li>You can unsubscribe from any of our SMS communications by modifying your marketing preferences on your profile dashboard.</li>
                    </ol>

                    <p><strong>Operation of service communications:</strong></p>
                    <p>This is separate to the marketing communications, ResDiary will send the User the following for the operation of services:</p>
                    <ol>
                        <li>Notifications via email or SMS to confirm your booking details.</li>
                        <li>An email survey post dining. </li>
                    </ol>

                    <h4>6. Technical Requirements</h4>
                    <p>Use of the Services requires Internet access through your computer or mobile device. You are responsible for all mobile carrier charges resulting from your use of the Services, including from any notifications provided by the Services. ResDiary does not guarantee that the Services will be compatible with all devices or will be supported by all mobile carriers. You may be required to have JavaScript (or similar technologies) enabled to use the ResDiary Site, and some features and portions of the ResDiary Site (including, but not limited to, making, modifying, or canceling reservations) may not be accessible with JavaScript disabled.</p>

                    <h4>7. Modifications to Services</h4>
                    <p>ResDiary reserves the right, in its sole discretion, to modify the Services from time to time and without notice, including, without limitation, by removing, adding, or modifying portions of the ResDiary Site, ResDiary Application and/or Restaurants. ResDiary shall have no liability to you for any of the foregoing actions. If you object to any such changes, your sole recourse shall be to cease using the Services. Continued use of the Services following any such changes shall indicate your acknowledgment of such changes and satisfaction with all the Services.</p>

                    <h4>8. Intellectual Property Rights and Grant of Rights to User</h4>
                    <p>The features, information, and materials provided and depicted through the Services are protected by copyright, trademark, patent, and other intellectual property laws. All text, graphical content, video, data, and other content made available through the Services (collectively, the "ResDiary Content") are provided to you by ResDiary or its partners or licensors solely to support your permitted use of the Services. The ResDiary Content may be modified from time to time by ResDiary in its sole discretion. Except as expressly set forth herein, no license is granted to you for any other purpose, and any other use of the Services or the ResDiary Content by you shall constitute a material breach of this Agreement. ResDiary and its partners or licensors retain all rights in the Services and ResDiary Content and any associated patents, trademarks, copyrights, trade secrets, or other intellectual property rights. No license, right, or interest in any trademarks of ResDiary or any third party is granted under this Agreement. </p>

                    <h4>9. Application Licence</h4>
                    <p>Subject to the terms and conditions of this Agreement, ResDiary grants you a non-exclusive, non-transferable, revocable license to use the ResDiary Application on your compatible mobile devices, solely to support your permitted use of the Services.</p>

                    <h4>10. Use Restrictions</h4>
                    <p>The Services and ResDiary Content are offered solely for your personal use for the purposes described in this Agreement. Any and all other uses are prohibited. ResDiary expressly reserves all its rights and remedies under applicable laws. ResDiary reserves the right, in its sole discretion, to refuse service, remove or edit content including reviews, cancel reservations, or deny access to the Services. </p>
                    <p>You agree not to do the following (and not to allow any third party to): </p>
                    <ol>
                        <li>Use any robot, spider, scraper, or other automatic or manual device, process, or means to access the Services or copy any ResDiary Content, except as expressly authorised by ResDiary.</li>
                        <li>Take any action that imposes or may impose (in ResDiary's sole determination) an unreasonable or a disproportionately large load on the Services or ResDiary's infrastructure.</li>
                        <li>Utilise any device, software, or routine that will interfere or attempt to interfere with the functionality of the Services.</li>
                        <li>Rent, lease, copy, provide access to or sublicense any portion of the Services or ResDiary Content to a third party.</li>
                        <li>Use any portion of the Services or ResDiary Content to provide, or incorporate any portion of the Services or ResDiary Content into, any product or service provided to a third party.</li>
                        <li>Reverse engineer, decompile, disassemble, or otherwise seek to obtain the source code or non-public APIs to the Services, except to the extent expressly permitted by applicable law (and then only upon advance notice to ResDiary).</li>
                        <li>Modify any Services or ResDiary Content or create any derivative product from any of the foregoing.</li>
                        <li>Remove or obscure any proprietary or other notices contained in the Services or ResDiary Content. </li>
                        <li>Use the Services or ResDiary Content for any illegal purpose. </li>
                        <li>Publicly disseminate information regarding the performance of the Services, or ResDiary Content, or access or use the Services or ResDiary Content for competitive analysis or benchmarking purposes.</li>
                    </ol>

                    <h4>11. Termination</h4>
                    <p>ResDiary may suspend your ability to use all or any element of the Services or may terminate this Agreement effective immediately, without notice or explanation. Without limiting the foregoing, ResDiary may suspend your access to the Services if we believe you to be in violation of any part of this Agreement, including any ResDiary Policies.</p>

                    <h4>12. Reviews, Comments, Communications, and Other Content</h4>
                    <p>The Services may permit you to submit reviews, comments, and ratings, send emails and other communications; and submit suggestions, ideas, questions, or other information for publication and distribution to restaurants and other third parties ("User Content"). Any such User Content must not be illegal, threatening, obscene, racist, defamatory, libellous, pornographic, infringing of intellectual property rights, promoting of illegal activity or harm to groups and/or individuals, invasive of privacy, purposely false or otherwise injurious to third parties or objectionable and must not consist of or contain software, computer viruses, commercial solicitation, political campaigning, chain letters, mass mailings, any form of "spam" or references to illegal activity, malpractice, purposeful overcharging, false advertising or health code violations (e.g. foreign objects in food, food poisoning, etc.).</p>
                    <p>You may not use a false email address, impersonate any person or entity, or otherwise mislead as to the origin of User Content. ResDiary reserves the right (but has no obligation) to monitor, remove, or edit User Content in ResDiary's sole discretion, including if User Content violates this Agreement (including any ResDiary Policies), but you acknowledge that ResDiary may not regularly review submitted User Content.</p>
                    <p>If you do submit User Content, and unless we indicate otherwise, you grant ResDiary a non exclusive, perpetual, royalty-free, irrevocable, and fully sublicensable (through multiple tiers) right to use, modify, reproduce, adapt, translate, publish, create derivative works from, distribute, display, and otherwise exploit such User Content throughout the world in any media. If you submit a private review this will not appear on the resdiary.com portal. If you submit a public review, it will appear on the portal, and you can choose it to appear with your name attached or anonymously. Venues will be able to obtain the email address and booking details of all reviews submitted, and may contact you about your review. ResDiary takes no responsibility and assumes no liability for any User Content submitted by you or any other User or third party.</p>

                    <h4>13. Your Representations and Indemnity</h4>
                    <p>You confirm that you own or otherwise control all of the rights to any User Content submitted by you; that all User Content submitted by you is accurate; and that exploitation of such User Content by ResDiary and its other Users, partners, and licensees will not violate this Agreement, cause injury to any person or entity, or infringe any third-party rights.</p>
                    <p>You will indemnify ResDiary and its managers, shareholders, officers, directors, employees, representatives and agents (collectively, the "Indemnitees"), against any loss, damage or cost incurred by us arising out of your use of the ResDiary Site. This includes, without limitation, the Services, the ResDiary Content or any other information accessible over or through the ResDiary Site, including information obtained from linked sites, any User Content submitted by you or your violation of this Agreement, our <a href="@appConfig.ResDiaryPrivacyStatement" target="_blank"><span class="link-text">privacy policy</span></a> or any other laws, regulations and rules. </p>
                    <p>You will also indemnify the Indemnitees against any claims that information or material which you have submitted to us is in violation of any law or in breach of any third party rights (including, but not limited to, claims in respect of defamation, invasion of privacy, breach of confidence, infringement of copyright or infringement of any other intellectual property right). We reserve the right to exclusively defend and control any claims arising from the above and any such indemnification matters and that you will fully cooperate with us in any such defences.</p>

                    <h4>14. Liability Limitations</h4>
                    <p>Except as prohibited by law, in no event shall ResDiary be liable for any injury, loss, claim, damage or any special, exemplary, punitive, incidental or consequential damages of any kind, whether based in contract, tort or otherwise, which arises out of or is any way connected with the following: </p>
                    <ol>
                        <li>This agreement. </li>
                        <li>Any use of the Services, the ResDiary Site or the ResDiary Content. </li>
                        <li>Any failure or delay (including, but not limited to, the use or inability to use any component of the Services or the ResDiary Site for reservations). </li>
                        <li>The performance or non-performance of any restaurant in connection with the Services. </li>
                    </ol>
                    <p>In addition, you specifically understand and agree that any third party directing Users to the ResDiary Site by referral, link or any other means is not liable to the User for any reason whatsoever, including but not limited to damages or loss associated with the use of the services, the ResDiary Site or the ResDiary Content. ResDiary is neither an agent of, nor is connected with any affiliated restaurant in which a User has made a reservation. ResDiary is not liable for any injury, loss, claim, damage or any special, exemplary, punitive, incidental or consequential damages of any kind, whether based in contract, tort or otherwise, which arises out of or is in any way connected with a User's visit to an affiliated restaurant.
        You and ResDiary understand and agree that the disclaimers, exclusions, and limitations in this section are essential elements of this Agreement and that they represent a reasonable allocation of risk. In particular, you understand that ResDiary would be unable to make the Services available to you except on these terms and agree that this Agreement will survive and apply even if any limited remedy specified in this Agreement is found to have failed of its essential purpose.</p>

                    <h4>15. Disclaimer of Warranties</h4>
                    <p>The Services, all ResDiary Content, and any other information, products, and materials contained in or accessed through the Services, are provided to you on an "as is" basis and without warranty of any kind. ResDiary expressly disclaims all representations, warranties, conditions, or indemnities, express or implied, including, without limitation, any warranty of merchantability, fitness for a particular purpose, title or non-infringement, or any warranty arising from a course of dealing, performance, or trade usage. ResDiary does not warrant that your use of the Services will be uninterrupted or error-free, that ResDiary will review the information or materials made available through the Services for accuracy or that it will preserve or maintain any such information or materials without loss. ResDiary shall not be liable for delays, interruptions, service failures, or other problems inherent in the use of the internet and electronic communications or other systems outside the reasonable control of ResDiary.</p>
                    <p>The foregoing disclaimers apply to the maximum extent permitted by law. You may have other statutory rights, however, the duration of statutorily required warranties, if any, shall be limited to the maximum extent permitted by law.</p>

                    <h4>16. Links to Third Party Websites</h4>
                    <p>The Services may contain links to websites operated by parties other than ResDiary. Such links are provided for your reference only, and ResDiary does not control such websites and is not responsible for their content. ResDiary's inclusion of any links to such websites does not imply any endorsement of the material on such websites or any association with their operators. ResDiary is not liable for any such third-party websites or any content, features, products, or services made available through such third-party websites.</p>

                    <h4>17. Release</h4>
                    <p>Restaurants are solely responsible for their interactions with you and any and all claims, injuries, illnesses, damages, liabilities, and costs ("Claims") suffered by you including without limitation interaction with or visit to any Restaurant or from any product or service of any Restaurant.</p>

                    <h4>18. Notify Us of Infringers</h4>
                    <p>If you believe any of the Services violate your rights, please contact us on <a href="mailto:privacy@resdiary.com">privacy@resdiary.com</a>. </p>

                    <h4>19. Severability</h4>
                    <p>If any of the provisions, or portions thereof, of this Agreement are found to be invalid under any applicable statute or rule of law, then, that provision (or portion thereof) notwithstanding, this Agreement shall remain in full force and effect and such provision or portion thereof shall be deemed omitted.</p>

                    <h4>20. No Assignment</h4>
                    <p>This Agreement and the rights granted and obligations undertaken hereunder may not be transferred, assigned or delegated in any manner you, but may be so transferred, assigned or delegated by ResDiary.</p>

                    <h4>21. Waiver</h4>
                    <p>Any waiver of any provision of this Agreement, or a delay by any party in the enforcement of any right hereunder, shall neither be construed as a continuing waiver nor create an expectation of non-enforcement of that or any other provision or right.</p>

                    <h4>22. Choice of Law</h4>
                    <p>The laws of Scotland govern these Terms of Use. You hereby consent to the exclusive jurisdiction of the Scottish courts in all disputes arising out of or relating to this Agreement.
        ResDiary may, and you may not, assign, convey, subcontract or delegate rights, duties or obligations hereunder.</p>

                    <h4>23. Right to Erasure</h4>
                    <p>If you wish to delete your data, please notify us at <a href="mailto:privacy@resdiary.com">privacy@resdiary.com</a>. </p>
                </ModalBody>
                <ModalFooter>
                    <Button className="rd-button" onClick={toggle}>{intl.formatMessage({ id: 'Common.Close' })}</Button>{' '}
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default injectIntl(ResdiaryTermsAndConditions);