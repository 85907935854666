import React, { useState, Fragment, useContext, useEffect } from 'react';
import PropTypes from 'prop-types'
import VoucherPreviewCard from './VoucherPreviewCard';
import { injectIntl, FormattedMessage } from 'react-intl';
import LoadingSpinner from './LoadingSpinner';
import SetupContext from './../contexts/SetupContext';

function VouchersPreviewPage(props) {
    const { intl } = props;
    const setupContext = useContext(SetupContext);
    const [selectedVoucher, setSelectedVoucher] = useState(null);

    useEffect(() => {
        if (!setupContext.restaurantName && props.microsite) {
            props.getSetupContext(props.microsite, true);
        }
    }, [setupContext, props.microsite]);

    function toggleSelectedVoucher(voucher) {
        if (selectedVoucher && voucher.voucherId === selectedVoucher.voucherId) {
            setSelectedVoucher(null);
        } else {
            setSelectedVoucher(voucher);
        }
    }

    function getVoucherRows() {
        if (setupContext && setupContext.vouchers) {
            return setupContext.vouchers.map(v =>
                <VoucherPreviewCard
                    key={v.voucherId}
                    voucher={v}
                    currencySymbol={setupContext.currencySymbol}
                    voucherCost={v.cost}
                    voucherTitle={v.name}
                    voucherId={v.voucherId}
                    monthsValid={v.monthsValid}
                    onClick={toggleSelectedVoucher}
                    selected={selectedVoucher && selectedVoucher.voucherId === v.voucherId}
                />
            );
        }
        return null;
    }


    if (props.isLoading) {
        return <LoadingSpinner loadingText={intl.formatMessage({ id: 'Common.LoadingVouchers' })} />;
    }

    return (
        <Fragment>
            <div className="clickable-vouchers-container">
                <h5> <FormattedMessage id="Vouchers.SelectAVoucher" /></h5>
                <div className="d-flex vouchers-list">
                    {getVoucherRows()}
                </div>
            </div>
            {selectedVoucher &&
                <Fragment>
                    <div className="selected-voucher-information">
                        <h5 className="voucher-name">{selectedVoucher.name}</h5>
                        <h5>{selectedVoucher.description}</h5>
                        <div className="validity">
                            <span className="bold pr-1"><FormattedMessage id="Vouchers.Validity" />:</span>
                        <FormattedMessage
                            id={selectedVoucher.monthsValid === 0 ? "Vouchers.ValidityMessageNoExpiry" : selectedVoucher.monthsValid === 1 ? "Vouchers.ValidityMessageSingleMonth" : "Vouchers.ValidityMessageMultipleMonths"}
                            values={{
                                numberOfMonths: selectedVoucher.monthsValid,
                            }} />
                        </div>
                        {selectedVoucher.termsAndConditions &&
                            <div className="terms">
                                <div className="bold">
                                    <FormattedMessage id="Vouchers.Terms" />:
                                </div>
                                {selectedVoucher.termsAndConditions}
                            </div>
                        }
                    </div>
                </Fragment>
            }

        </Fragment>
    );
}

VouchersPreviewPage.propTypes = {
    microsite: PropTypes.string.isRequired,
    getSetupContext: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
};

export default injectIntl(VouchersPreviewPage);