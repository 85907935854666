import en_common from "../translations/Common.json";
import en_vouchers from "../translations/Vouchers.json";
import nb_common from "../translations/Common.nb.json";
import no_common from "../translations/Common.no.json";
import de_common from "../translations/Common.de.json";
import it_common from "../translations/Common.it.json";
import es_common from "../translations/Common.es.json";
import pt_common from "../translations/Common.pt.json";
import ja_common from "../translations/Common.ja.json";
import sk_common from "../translations/Common.sk.json";
import fr_common from "../translations/Common.fr.json";
import it_vouchers from "../translations/Vouchers.it.json";
import pt_vouchers from "../translations/Vouchers.pt.json";
import no_vouchers from "../translations/Vouchers.no.json";

// import new json files here...
// import xxx_common from '../translations/Common.xxx.json';

function useTranslations() {
  const en = { ...en_common, ...en_vouchers };
    const allMessages = {
    en: en,
    no: { ...en, ...no_common, ...no_vouchers },
    nb: { ...en, ...nb_common },
    de: { ...en, ...de_common },
    it: { ...en, ...it_common, ...it_vouchers },
    es: { ...en, ...es_common },
    pt: { ...en, ...pt_common, ...pt_vouchers },
    ja: { ...en, ...ja_common },
    sk_SK: { ...en, ...sk_common },
    fr: { ...en, ...fr_common },
    // other translations added here...
    // {
    //     ...en,
    //     ...xxx_common,
    // }
  };

  const language = navigator.language.split(/[-_]/)[0]; // language without region code
  const messages =
    language in allMessages ? allMessages[language] : allMessages["en"];
  return { language, messages };
}

export default useTranslations;
