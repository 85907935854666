import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import ValidationMessage from './ValidationMessage';

function ValidationInput(props) {
    const [fieldBlurred, setFieldBlurred] = useState(false);

    function getClassNamesString() {
        if (props.hasError && fieldBlurred) {
            if (props.className) return `is-invalid ${props.className}`;
            else return 'is-invalid';
        } else {
            if (props.className) return props.className;
            else return null;
        }
    }

    function onChange(event) {
        props.onChange(event.target.value);
    }

    function onBlur() {
        setFieldBlurred(true);
    }

    return (
        <div className="validation-input-wrapper">
            <Input
                className={getClassNamesString()}
                defaultValue={props.value}
                onInput={onChange}
                data-testid={props.testId}
                innerRef={props.innerRef}
                name={props.name}
                type={props.type || 'text'}
                placeholder={props.placeholder}
                rows={props.rows}
                maxLength={props.maxLength}
                autoFocus={props.autoFocus}
                disabled={props.disabled}
                onBlur={() => onBlur()}
            />

            <div className="validation-input-feedback">
                {props.hasError && fieldBlurred && <ValidationMessage message={props.errorMessage} />}
            </div>
        </div>
    );
}

ValidationInput.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    testId: PropTypes.string,
    innerRef: PropTypes.func,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    rows: PropTypes.string,
    maxLength: PropTypes.string,
    errorMessage: PropTypes.string,
    autoFocus: PropTypes.bool,
    hasError: PropTypes.bool,
    displayCharacterCount: PropTypes.bool,
    hideValidationMessage: PropTypes.bool
};

export default ValidationInput;
