import React, { useContext, useState, Fragment, useEffect } from 'react';
import SetupContext from './../contexts/SetupContext';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as DownArrow } from '../content/icons/down-arrow.svg';
import { ReactComponent as UpArrow } from '../content/icons/up-arrow.svg';
import moment from "moment";

const PurchasedItemSummary = (props) => {
    const setupContext = useContext(SetupContext);

    const [termsExpanded, setTermsExpanded] = useState(false);
    const [termsAndConditions, setTermsAndConditions] = useState(null);

    useEffect(() => {
        if (props.purchasedVoucher && setupContext) {
            const matchingVoucherType =
                setupContext.vouchers.find(v => v.voucherId === props.purchasedVoucher.voucherId);
            if (matchingVoucherType) {
                setTermsAndConditions(matchingVoucherType.termsAndConditions);
            }
        }
    }, [props.purchasedVoucher, setupContext]);

    function calculateValidityDate() {
        const matchingVoucher = setupContext.vouchers.find(v => v.voucherId === props.purchasedVoucher.voucherId);
        if (matchingVoucher) {
            return matchingVoucher.monthsValid !== 0
                ? moment().add(matchingVoucher.monthsValid, 'months').format("LL")
                : <FormattedMessage id="Common.NotApplicable" />;
        }
        return null;
    }

    function getAddress() {
        return `${props.purchasedVoucher.recipientDetails.deliveryAddress.house} ${props.purchasedVoucher.recipientDetails.deliveryAddress.street},
                ${props.purchasedVoucher.recipientDetails.deliveryAddress.town}, ${props.purchasedVoucher.recipientDetails.deliveryAddress.postcode}`;
    }

    return (
        <div className={props.isMobileView ? "purchased-voucher" : "purchased-voucher d-flex"}>
            <div className="voucher-price">{setupContext.currencySymbol}{props.purchasedVoucher.unitCost}</div>
            <div className="voucher-information">
                <div className="d-flex">
                    <div><h6 className="text-uppercase font-weight-bold">{props.purchasedVoucher.name} <span className="font-weight-normal">(QTY: {props.purchasedVoucher.quantity})</span></h6></div>
                    <div className="ml-auto"><h6>{setupContext.currencySymbol}{(props.purchasedVoucher.quantity * props.purchasedVoucher.unitCost).toFixed(2)}</h6></div>
                </div>
                <div className="voucher-codes">
                    <div className="font-weight-bold"><FormattedMessage id="Vouchers.VoucherCode" /></div>
                    {props.purchasedVoucher.voucherReferences.map(vc =>
                        <div className="voucher-reference">
                            {vc}
                        </div>
                    )}
                </div>
                <div className="validity-info">
                    <div className="font-weight-bold"><FormattedMessage id="Vouchers.ValidUntil" /></div>
                    <div>{calculateValidityDate()}</div>
                </div>
                {props.purchasedVoucher.recipientDetails && props.purchasedVoucher.recipientDetails.fullName &&
                    <div className="recipient-info">
                        <div className="font-weight-bold"><FormattedMessage id="Vouchers.Recipient" /></div>
                        {props.purchasedVoucher.recipientDetails.email &&
                        <div className="recipient">{props.purchasedVoucher.recipientDetails.fullName} ({props.purchasedVoucher.recipientDetails.email})</div>
                        }
                    {props.purchasedVoucher.recipientDetails.deliveryAddress && props.purchasedVoucher.recipientDetails.deliveryAddress.house && props.purchasedVoucher.recipientDetails.deliveryAddress.street &&
                        props.purchasedVoucher.recipientDetails.deliveryAddress.town && props.purchasedVoucher.recipientDetails.deliveryAddress.postcode &&
                            <div className="recipient">{props.purchasedVoucher.recipientDetails.fullName} ({getAddress()}) </div>
                        }
                        <div className="message">{props.purchasedVoucher.recipientDetails.message}</div>
                    </div>
                }
                <div>
                    {termsAndConditions &&
                        <Fragment>
                            <div className="expandable-terms" onClick={() => setTermsExpanded(!termsExpanded)}><FormattedMessage id="Vouchers.VoucherTerms" />{termsExpanded ? <UpArrow /> : <DownArrow />}</div>
                            {termsExpanded && 
                                <div>{termsAndConditions}</div>
                            }
                        </Fragment>
                    }
                </div>
            </div>
        </div>
    );
}

export default PurchasedItemSummary;