import React, { Fragment, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { FormattedMessage, injectIntl } from 'react-intl';
import VoucherService from './../services/VoucherService';
import LoadingSpinner from './LoadingSpinner';
import infoBarType from '../enums/infoBarType';
import deliveryMethod from '../enums/deliveryMethod';
import InfoBars from './InfoBars';
import { ReactComponent as SuccessIcon } from '../content/icons/Icon-Success.svg';
import PurchasedItemSummary from './PurchasedItemSummary';
import SegmentHelper from './../helpers/SegmentHelper'
import SetupContext from './../contexts/SetupContext';
import useScreenSize from '../hooks/useScreenSize'
import VoucherProcessingMessage from './VoucherProcessingMessage';
let _ID = 0;

const SuccessPage = (props) => {
    const { intl } = props;
    const setupContext = useContext(SetupContext);

    SuccessPage.propTypes = {
        location: PropTypes.object.isRequired,
        microsite: PropTypes.string.isRequired
    };

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [hasCreatedVouchers, setHasCreatedVouchers] = useState(false);
    const [isCreatingVouchers, setIsCreatingVouchers] = useState(false);
    const [billingDetails, setBillingDetails] = useState(null);
    const [totalPrice, setTotalPrice] = useState(null);
    const [soldVouchers, setSoldVouchers] = useState(null);
    const [infoBars, setInfoBars] = useState([]);
    const { isMobileView } = useScreenSize();

    const addErrorBar = message => {
        setInfoBars([...infoBars, { id: _ID++, type: infoBarType.error, message: message }]);
    };

    useEffect(() => {
        if (!setupContext.restaurantName && props.microsite) {
            props.getSetupContext(props.microsite);
        } else {
            setIsCreatingVouchers(true);

            const params = queryString.parse(props.location.search);
            const checkoutSessionId = params.session_id;
            const storedStateString = window.sessionStorage.getItem(checkoutSessionId);
            const storedState = JSON.parse(storedStateString, checkoutSessionId);

            if (checkoutSessionId && storedState) {
                VoucherService.createVoucherOrder(props.microsite, createVoucherOrderDto(storedState, checkoutSessionId))
                    .then(response => {
                        setSoldVouchers(response.vouchersSold);
                        setBillingDetails(response.billingDetails);
                        setTotalPrice(storedState.totalCartPrice);
                        setHasCreatedVouchers(true);
                        setIsCreatingVouchers(false);
                        SegmentHelper.sendPageLoad("Success");
                    }).catch((error) => {
                        addErrorBar(error.message);
                        setIsCreatingVouchers(false);
                        setShowErrorMessage(true);
                        SegmentHelper.sendPageLoad("Success");
                    }).then(() => {
                        window.analytics.page("VoucherPurchase Success Page");
                    });
            }
        }
    }, [setupContext, props.microsite]);

    function createVoucherOrderDto(storedState, checkoutSessionId) {
        return {
            vouchersOrdered: storedState.vouchersOrdered.map(vo => {
                return {
                    voucherId: vo.voucherId,
                    quantity: vo.quantity,
                    postageMethodId: vo.deliveryDetails.deliveryOption,
                    chargeForDelivery: vo.deliveryDetails.chargeForDelivery,
                    recipientDetails: {
                        fullName: `${vo.recipientDetails.firstName} ${vo.recipientDetails.lastName}`,
                        email: vo.recipientDetails.email,
                        message: vo.recipientDetails.message,
                        deliveryAddress: vo.deliveryMethod === deliveryMethod.delivery ? vo.deliveryDetails : null,
                    },
                    sendNotificationToRecipient: vo.deliveryMethod === deliveryMethod.email
                }
            }),
            billingDetails: {
                firstName: storedState.billingDetails.firstName,
                surname: storedState.billingDetails.lastName,
                email: storedState.billingDetails.email
            },
            stripeCheckoutSessionId: checkoutSessionId,
            sendNotificationToPurchaser: true
        }
    }

    if (hasCreatedVouchers) {
        return <Fragment>
            <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
            {hasCreatedVouchers && billingDetails &&
                <Fragment>
                    <div className={isMobileView ? "success-page-wrapper mobile" : "success-page-wrapper"}>
                        <div className="confirmation-heading">
                            <div className="progress-icon-container success-icon">
                                <SuccessIcon />
                            </div>
                            <FormattedMessage id={"Common.ThanksForYourPurchase"} tagName={"h2"} />
                            <FormattedMessage id={"Common.PurchaseConfirmation"} values={{ email: billingDetails.email }} />
                        </div>
                        <div className="purchased-items-container">
                            {soldVouchers && soldVouchers.map(ci =>
                                <PurchasedItemSummary
                                    key={ci.voucherId}
                                    purchasedVoucher={ci}
                                    isMobileView={isMobileView}
                                />
                            )}
                        </div>
                        <div className="total-paid-wrapper">
                            <div className="font-weight-bold ml-auto total-price text-uppercase">
                                <h6><FormattedMessage id="Vouchers.TotalPaid" /> {setupContext.currencySymbol}{totalPrice}</h6>
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
        </Fragment>
    } else if (showErrorMessage)  {
        return <VoucherProcessingMessage
            headerText = {intl.formatMessage({ id: 'Vouchers.VoucherFailureMessageHeading' })}
            bodyText={{
                mainBodyText: intl.formatMessage({ id: 'Vouchers.VoucherFailureMessageBody' })
            }}></VoucherProcessingMessage>;
    } else if (!hasCreatedVouchers && !showErrorMessage && isCreatingVouchers) {
        return <Fragment><VoucherProcessingMessage headerText={intl.formatMessage({ id: 'Vouchers.VoucherProcessingHeaderMessage' })}
            bodyText={{
                mainBodyText: intl.formatMessage({ id: 'Vouchers.VoucherProcessingBodyMainMessage' }),
                secondaryBodyText: intl.formatMessage({ id: 'Vouchers.VoucherProcessingBodySecondaryMessage' }),
            }}></VoucherProcessingMessage><LoadingSpinner loadingText={intl.formatMessage({ id: 'Common.CreatingVouchers' })} /></Fragment>;
    } else {
        return null;
    }
}

export default injectIntl(SuccessPage);