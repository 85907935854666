import React, { useState, useEffect, useCallback } from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import ChooseVouchersPage from './components/ChooseVouchersPage';
import VouchersPreviewPage from './components/VouchersPreviewPage';
import VoucherCartPage from './components/VoucherCartPage';
import SuccessPage from './components/SuccessPage'
import NotFoundPage from './components/404NotFoundPage'
import ErrorBoundary from './components/ErrorBoundary';
import SegmentHelper from './helpers/SegmentHelper';
import VoucherService from './services/VoucherService';
import Voucher from './domainObjects/Voucher';
import DeliveryDetails from './domainObjects/DeliveryDetails';
import RecipientDetails from './domainObjects/RecipientDetails';
import deliveryMethod from './enums/deliveryMethod';
import sendingOption from './enums/sendingOption';
import SetupContext from './contexts/SetupContext';
import InfoBars from './components/InfoBars';
import infoBarType from './enums/infoBarType';
import LoadingSpinner from './components/LoadingSpinner';

let _ID = 0;

export default function App() {
    const [orderItems, setOrderItems] = useState([]);
    const [setupInfo, setSetupInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasFetchedSetupInfo, setHasFetchedSetupInfo] = useState(false);
    const [infoBars, setInfoBars] = useState([]);

    const addErrorBar = useCallback(message => {
        setInfoBars([...infoBars, { id: _ID++, type: infoBarType.error, message: message }]);
    }, []);


    function getSetup(microsite, includePreviewHeader) {
        setIsLoading(true);
        if (!hasFetchedSetupInfo) {
            VoucherService.getVouchersSetup(microsite)
                .then(getSetupResponse => {
                    setSetupInfo({
                        restaurantName: getSetupResponse.name,
                        postageMethods: getSetupResponse.postageMethods,
                        currencySymbol: getSetupResponse.currencySymbol,
                        logoUrl: getSetupResponse.logoUrl,
                        stripePublishableKey: getSetupResponse.stripeInfo.stripePublishableKey,
                        stripeAccountId: getSetupResponse.stripeInfo.stripeAccountId,
                        requiresPurchaserFullName: getSetupResponse.requiresPurchaserFullName,
                        termsAndConditions: getSetupResponse.termsAndConditions,
                        providerAddress: getSetupResponse.address,
                        providerEmailAddress: getSetupResponse.emailAddress,
                        providerPhoneNumber: getSetupResponse.reservationPhoneNumber,
                        providerCountryCode: getSetupResponse.countryCode,
                        vouchers: getSetupResponse.vouchers.sort((a, b) => a.cost - b.cost).map(v => new Voucher(v)),
                        sellsVouchers: getSetupResponse.sellsVouchers,
                        displayPreviewHeader: includePreviewHeader
                    });
                })
                .catch(() => {
                    addErrorBar();
                })
                .finally(() => {
                    setHasFetchedSetupInfo(true);
                    setIsLoading(false);
                });
        }
    }

    function addOrderItemToCart(orderItem) {
        let orderItemsCopy = [...orderItems];

        // Calculate whether same postage method to an address already in basket to avoid double charging
        if (orderItem.deliveryMethod === deliveryMethod.delivery) {
            const itemsWithMatchingDeliveryOption =
                orderItemsCopy.filter(oi => oi.deliveryDetails.deliveryOption === orderItem.deliveryDetails.deliveryOption);
            {
                const deliveryAddressInBasketAlready = itemsWithMatchingDeliveryOption.some(item => {
                    return JSON.stringify(item.recipientDetails).toUpperCase().replace(/\s+/g, '') ===
                        JSON.stringify(orderItem.recipientDetails).toUpperCase().replace(/\s+/g, '') &&
                        JSON.stringify(item.deliveryDetails).toUpperCase().replace(/\s+/g, '') ===
                        JSON.stringify(orderItem.deliveryDetails).toUpperCase().replace(/\s+/g, '');
                });

                if (deliveryAddressInBasketAlready) {
                    orderItem.deliveryDetails.chargeForDelivery = false;
                }
            }
        }

        setOrderItems([...orderItems, orderItem]);

        SegmentHelper.trackClickWithProperties("Web Voucher AddCart",
            {
                Quantity: orderItem.quantity,
                GiftOrPersonal: orderItem.sendingOption === sendingOption.sendToMe ? "Personal" : "Gift",
                Recipient_Name: `${orderItem.recipientDetails.firstName} ${orderItem.recipientDetails.lastName}`,
                Recipient_Email: orderItem.recipientDetails.email,
                Message: orderItem.recipientDetails.message
            });
        SegmentHelper.sendCartEvent("Voucher added");
    }

    function updateOrderItem(orderItem) {
        let newOrderItems = [...orderItems];
        const existingItemIndex = newOrderItems.findIndex(item => item.uniqueIdentifier === orderItem.uniqueIdentifier);
        const existingQuantity = newOrderItems[existingItemIndex].quantity;
        const newQuantity = orderItem.quantity;
        newOrderItems[existingItemIndex] = orderItem;
        if (newQuantity > existingQuantity) {
            SegmentHelper.sendCartEvent("Voucher quantity increased");
        } else {
            SegmentHelper.sendCartEvent("Voucher quantity decreased");
        }
        setOrderItems(newOrderItems);
    }

    function buyOrderItemNow(orderItem, redirectCallback) {
        if (!orderItem.deliveryMethod && setupInfo.postageMethods.length === 1) {
            orderItem.deliveryMethod = deliveryMethod.email;
            orderItem.deliveryDetails.deliveryOption = setupInfo.postageMethods[0].postageId;
        }
        setOrderItems([orderItem]);

        SegmentHelper.trackClickWithProperties("Web Voucher CompletePurchase",
            {
                Quantity: orderItem.quantity,
                GiftOrPersonal: orderItem.sendingOption === sendingOption.sendToMe ? "Personal" : "Gift",
                Recipient_Name: `${orderItem.recipientDetails.firstName} ${orderItem.recipientDetails.lastName}`,
                Recipient_Email: orderItem.recipientDetails.email,
                Message: orderItem.recipientDetails.message
            });

        if (redirectCallback) {
            redirectCallback();
        }
    }

    function removeOrderItem(uniqueIdentifier) {
        const newOrderItems = [...orderItems];
        const existingItemIndex = newOrderItems.findIndex(item => item.uniqueIdentifier === uniqueIdentifier);
        if (existingItemIndex > -1) {
            newOrderItems.splice(existingItemIndex, 1);
            setOrderItems(newOrderItems);

            SegmentHelper.sendCartEvent("Voucher removed");
        }
    }

    function clearOrderItems() {
        setOrderItems([]);
        SegmentHelper.sendCartEvent("Cart emptied");
    }

    function getTotalCost() {
        let totalCost = 0;
        orderItems.forEach(item => {
            let postageCost = 0;
            const value = item.unitCost * item.quantity;
            if (item.deliveryDetails.deliveryOption && item.deliveryDetails.chargeForDelivery) {
                const postageMethod = setupInfo.postageMethods.find(pm => pm.postageId === item.deliveryDetails.deliveryOption);
                postageCost = postageMethod.cost;
            }
            totalCost = totalCost + value + postageCost;
        });
        return totalCost.toFixed(2);
    }

    if (isLoading) {
        return <LoadingSpinner />;
    } else {
        if (setupInfo && setupInfo.sellsVouchers) {
            return (
                <ErrorBoundary>
                    <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
                    <SetupContext.Provider value={{ ...setupInfo, addErrorBar }}>
                        <Layout>
                            <Switch>
                                <Route exact path={"/:micrositeName"}
                                    render={({ match, history }) => {
                                        return <ChooseVouchersPage
                                            microsite={match.params.micrositeName}
                                            updateOrderItem={updateOrderItem}
                                            addOrderItemToCart={addOrderItemToCart}
                                            removeOrderItem={removeOrderItem}
                                            orderItems={orderItems}
                                            totalCost={getTotalCost()}
                                            history={history}
                                            clearOrderItems={clearOrderItems}
                                            isLoading={isLoading}
                                            buyOrderItemNow={buyOrderItemNow}
                                        />;
                                    }}
                                />
                                <Route exact path={"/:micrositeName/details"}
                                    render={({ match, history }) => {
                                        return <VoucherCartPage
                                            orderItems={orderItems}
                                            totalCost={getTotalCost()}
                                            removeOrderItem={removeOrderItem}
                                            updateOrderItem={updateOrderItem}
                                            setupInfo={setupInfo}
                                            history={history}
                                            microsite={match.params.micrositeName}
                                        />;
                                    }}
                                />
                                <Route exact path={"/:micrositeName/success"}
                                    render={({ match, history, location }) => {
                                        return <SuccessPage
                                            microsite={match.params.micrositeName}
                                            history={history}
                                            location={location}
                                        />;
                                    }}
                                />
                                <Route exact path={"/:micrositeName/preview"}
                                    render={({ match }) => {
                                        return (<VouchersPreviewPage isLoading={isLoading}
                                            microsite={match.params.micrositeName} />);
                                    }}
                                />
                                <Route component={NotFoundPage} />
                            </Switch>
                        </Layout>
                    </SetupContext.Provider>
                </ErrorBoundary>
            );
        } else {
            return (
                <ErrorBoundary>
                    <InfoBars infoBars={infoBars} setInfoBars={setInfoBars} />
                    <SetupContext.Provider value={{ ...setupInfo, addErrorBar }}>
                        <Layout displayPreviewHeader>
                            <Switch>
                                <Route exact path={"/:micrositeName"}
                                    render={({ match, history }) => {
                                        return <ChooseVouchersPage
                                            microsite={match.params.micrositeName}
                                            updateOrderItem={updateOrderItem}
                                            removeOrderItem={removeOrderItem}
                                            orderItems={orderItems}
                                            totalCost={getTotalCost()}
                                            history={history}
                                            clearOrderItems={clearOrderItems}
                                            isLoading={isLoading}
                                            getSetupContext={getSetup}
                                        />;

                                    }}
                                />
                                <Route exact path={"/:micrositeName/success"}
                                    render={({ match, history, location }) => {
                                        return <SuccessPage
                                            microsite={match.params.micrositeName}
                                            history={history}
                                            location={location}
                                            getSetupContext={getSetup}
                                        />;
                                    }}
                                />
                                <Route exact path={"/:micrositeName/preview"}
                                    render={({ match }) => {
                                        return <VouchersPreviewPage
                                            isLoading={isLoading}
                                            microsite={match.params.micrositeName}
                                            getSetupContext={getSetup}
                                        />;
                                    }}
                                />
                                <Route component={NotFoundPage} />
                            </Switch>
                        </Layout>
                    </SetupContext.Provider>
                </ErrorBoundary>
            );
        }
    }
}