import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { unregister } from './registerServiceWorker';
import './styles/styles.scss';
import useTranslations from './hooks/useTranslations';
import { IntlProvider } from 'react-intl';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
// eslint-disable-next-line react-hooks/rules-of-hooks
const { language, messages } = useTranslations();

ReactDOM.render(
    <IntlProvider locale={language} messages={messages}>
        <BrowserRouter basename={baseUrl}>
            <App />
        </BrowserRouter>
    </IntlProvider>,
    rootElement
);

unregister();