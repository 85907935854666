class DeliveryDetails {
    constructor(deliveryDetails) {
        this.deliveryOption = deliveryDetails ? deliveryDetails.DeliverOption : 0;
        this.house = deliveryDetails ? deliveryDetails.House : "";
        this.street = deliveryDetails ? deliveryDetails.Street : "";
        this.town = deliveryDetails ? deliveryDetails.Town : "";
        this.postcode = deliveryDetails ? deliveryDetails.Postcode : "";
        this.chargeForDelivery = true;
    }
}
export default DeliveryDetails;
