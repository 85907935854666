import React, { Fragment, useEffect, useState, useContext } from 'react';
import VoucherCartItem from './VoucherCartItem'
import ResdiaryTermsAndConditions from './ResdiaryTermsAndConditions';
import ValidationInput from './ValidationInput';
import { injectIntl } from 'react-intl';
import { CustomInput, Label, Button } from 'reactstrap';
import SegmentHelper from '../helpers/SegmentHelper'
import useScreenSize from '../hooks/useScreenSize'
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ChevronLeftIcon } from '../content/icons/chevron-left.svg';
import ButtonWrapper from './Button';
import SetupContext from './../contexts/SetupContext';
import ContactDetails from './../domainObjects/ContactDetails';
import deliveryMethod from './../enums/deliveryMethod';
import sendingOption from './../enums/sendingOption';
import VoucherService from '../services/VoucherService';
import CartHelper from '../helpers/CartHelper';
import { loadStripe } from '@stripe/stripe-js';
import RestaurantTermsAndConditions from './RestaurantTermsAndConditions.js';

const VoucherCartPage = (props) => {

    const { intl } = props;
    const setupContext = useContext(SetupContext);

    const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false);
    const [venueTermsAndConditionsAccepted, setVenueTermsAndConditionsAccepted] = useState(false);
    const [stripe, setStripe] = useState(null);
    const [redirectingToCheckout, setRedirectingToCheckout] = useState(false);
    const location = "Voucher cart";
    const [contactDetails, setContactDetails] = useState(new ContactDetails());
    const { isTabletView } = useScreenSize();

    useEffect(() => {
        if (!setupContext.vouchers) {
            props.history.push(`/${props.microsite}/`);
        }
        SegmentHelper.sendPageLoad(location);
    }, [location, setupContext]);

    useEffect(() => {
        async function fetchStripe() {

            let stripePromise;
            if (setupContext.stripeAccountId) {
                stripePromise = await loadStripe(setupContext.stripePublishableKey,
                    {
                        stripeAccount: setupContext.stripeAccountId
                    });
            } else {
                stripePromise = await loadStripe(setupContext.stripePublishableKey);
            }
            setStripe(stripePromise);
        }

        if (!stripe) {
            fetchStripe();
        }
    }, [setupContext]);
    
    function setContactDetailsField(fieldName, value) {
        const contactDetailsCopy = { ...contactDetails };
        contactDetailsCopy[fieldName] = value;
        setContactDetails(contactDetailsCopy);
    }

    function updateCartItemQuantity(quantity, cartItemUniqueId) {
        const cartItem = props.orderItems.find(ci => ci.uniqueIdentifier === cartItemUniqueId);
        if (quantity < cartItem.minimumQuantity) {
            props.removeOrderItem(cartItem.cartItemUniqueId);
        } else {
            cartItem.quantity = quantity;
            props.updateOrderItem(cartItem);
        }
    }


    function getAnalyticsProperties(voucherDtos) {
        let properties = {};
        voucherDtos.forEach((voucher, index) => {
            properties['Voucher_name' + (index + 1)] = voucher.name;
            properties['Voucher_cost' + (index + 1)] = voucher.cost;
            properties['Voucher_quantity' + (index + 1)] = voucher.quantity;
        });
        return { vouchers: properties, total_cost: props.totalCost };
    }

    async function redirectToCheckout() {
        if (!stripe) {
            return;
        }
        setRedirectingToCheckout(true);
        const host = (new URL(window.location.href)).host;

        SegmentHelper.sendButtonClicked("Pay with card");

        const postageMethods = [];
        const vouchersToOrder = [];

        props.orderItems.forEach(ci => {
            if (ci.sendingOption === sendingOption.sendToMe) {
                // When sending to self, take billing details for the recipient information if none have been set.
                if (ci.deliveryMethod === deliveryMethod.email && !ci.recipientDetails.firstName && !ci.recipientDetails.lastName && !ci.recipientDetails.email) {
                    ci.recipientDetails = { ...ci.recipientDetails, ...contactDetails };
                }
                const matchingItemInCart = CartHelper.findMatchingItemInCart(props.orderItems, ci, true);
                if (matchingItemInCart) {
                    // If item with same delivery details exists in cart, increase quantity of that item
                    if (matchingItemInCart.maximumQuantity >= matchingItemInCart.quantity + ci.quantity) {
                        matchingItemInCart.quantity = matchingItemInCart.quantity + ci.quantity;
                    }
                } else {
                    if (ci.deliveryDetails && ci.deliveryDetails.deliveryOption && ci.deliveryDetails.chargeForDelivery) {
                        postageMethods.push(ci.deliveryDetails.deliveryOption);
                    }
                    vouchersToOrder.push(ci);
                }
            } else {
                if (ci.deliveryDetails && ci.deliveryDetails.deliveryOption && ci.deliveryDetails.chargeForDelivery) {
                    postageMethods.push(ci.deliveryDetails.deliveryOption);
                }
                vouchersToOrder.push(ci);
            }
        });


        const voucherDto = {
            customerEmail: contactDetails.email,
            cancelUrl: `https://${host}/${encodeURIComponent(props.microsite)}/`,
            successUrl: `https://${host}/${encodeURIComponent(props.microsite)}/Success?session_id={CHECKOUT_SESSION_ID}`,
            postageMethodIds: postageMethods,
            vouchersCheckedOut: vouchersToOrder
        };

        SegmentHelper.trackClickWithProperties("Web Voucher Pay", getAnalyticsProperties(voucherDto.vouchersCheckedOut));

        VoucherService.generateVoucherCheckoutSession(props.microsite, voucherDto).then(async function (sessionId) {
            storeCartInfoInLocalStorage(sessionId, vouchersToOrder);
            await stripe.redirectToCheckout({
                sessionId: sessionId
            });
        });

    }

    function storeCartInfoInLocalStorage(checkoutSessionId, vouchersOrdered) {
        const cartInfoToStore = {
            vouchersOrdered: vouchersOrdered,
            billingDetails: {
                firstName: contactDetails.firstName,
                lastName: contactDetails.lastName,
                email: contactDetails.email
            },
            totalCartPrice: props.totalCost
        }
        window.sessionStorage.setItem(checkoutSessionId, JSON.stringify(cartInfoToStore));
    }


    function contactEmailHasError() {
        return !contactDetails.email ||
            !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contactDetails.email);
    }

    function payWithCardButtonDisabled() {
        return contactEmailHasError() ||
            !contactDetails.firstName ||
            !contactDetails.lastName ||
            !termsAndConditionsAccepted ||
            redirectingToCheckout ||
            (!venueTermsAndConditionsAccepted && setupContext.termsAndConditions);
    }

    return (
        <Fragment>
            <div className={isTabletView ? "cart-content-wrapper mobile" : "cart-content-wrapper"}>
                <div className="back-link d-flex" onClick={() => props.history.push(`/${props.microsite}/`)}>
                    <ChevronLeftIcon /><h5> <FormattedMessage id="Vouchers.SelectMoreVouchers" /></h5>
                </div>
                <div className={isTabletView ? "" : "d-flex"}>
                    <div className="contact-details">
                        <h4><FormattedMessage id="Vouchers.YourContactDetails" /></h4>
                        <Label><FormattedMessage id={"Common.FirstName"} /></Label>
                        <ValidationInput
                            type="text"
                            value={contactDetails.firstName}
                            onChange={(e) => setContactDetailsField("firstName", e)}
                            hasError={!contactDetails.firstName}
                            errorMessage={intl.formatMessage({ id: 'Vouchers.FieldNameRequired' },
                                {
                                    fieldName: intl.formatMessage({ id: 'Common.FirstName' })

                                }
                            )}
                        />
                        <Label><FormattedMessage id={"Common.LastName"} /></Label>
                        <ValidationInput
                            type="text"
                            value={contactDetails.lastName}
                            onChange={(e) => setContactDetailsField("lastName", e)}
                            hasError={!contactDetails.lastName}
                            errorMessage={intl.formatMessage({ id: 'Vouchers.FieldNameRequired' },
                                {
                                    fieldName: intl.formatMessage({ id: 'Common.LastName' })

                                }
                            )}
                        />
                        <Label><FormattedMessage id={"Common.EmailAddress"} /></Label>
                        <ValidationInput
                            type="email"
                            value={contactDetails.email}
                            onChange={(e) => setContactDetailsField("email", e)}
                            hasError={contactEmailHasError()}
                            errorMessage={intl.formatMessage({ id: 'Common.ValidEmailRequired' })}
                        />
                    </div>
                    <div className="order-summary">
                        <h4><FormattedMessage id="Vouchers.OrderSummary" /></h4>
                        {props.orderItems.length > 0 &&
                            <Fragment>
                                <div className="cart-items">
                                    {props.orderItems.map(cartItem =>
                                        <VoucherCartItem
                                            key={cartItem.voucherId}
                                            cartItem={cartItem}
                                            updateCartItemQuantity={updateCartItemQuantity}
                                            removeOrderItem={props.removeOrderItem}
                                            deliveryMethod={cartItem.deliveryDetails.deliveryOption ? setupContext.postageMethods.find(pm => pm.postageId === cartItem.deliveryDetails.deliveryOption) : null}
                                            isTabletView={isTabletView}
                                        />
                                    )}
                                </div>
                                <div className="total-price-wrapper">
                                    <h6 className="total"><FormattedMessage id={"Common.Total"} /> {setupContext.currencySymbol}{props.totalCost} </h6>
                                </div>
                                <div className="terms-and-conditions">
                                    <div className="d-flex">
                                        <CustomInput
                                            id="terms-accepted"
                                            className="custom-control-cursor-pointer"
                                            type="checkbox"
                                            onChange={(e) => setTermsAndConditionsAccepted(e.target.checked)}
                                            checked={termsAndConditionsAccepted}
                                        />
                                        <div className="mr-1">{intl.formatMessage({ id: 'Vouchers.AgreeToTermsAndConditions' })}</div>
                                        <ResdiaryTermsAndConditions />
                                    </div>
                                    { setupContext.termsAndConditions && (
                                        <div className="d-flex">
                                            <CustomInput
                                                id="venue-terms-accepted"
                                                className="custom-control-cursor-pointer"
                                                type="checkbox"
                                                onChange={(e) => setVenueTermsAndConditionsAccepted(e.target.checked)}
                                                checked={venueTermsAndConditionsAccepted}
                                            />
                                            <div className="mr-1">{intl.formatMessage({ id: 'Vouchers.AgreeToTermsAndConditions' })}</div>
                                            <RestaurantTermsAndConditions termsAndConditions={setupContext.termsAndConditions} />
                                        </div>
                                    )}
                                </div>
                                <ButtonWrapper
                                    text={redirectingToCheckout ? intl.formatMessage({ id: 'Common.Loading' }) : intl.formatMessage({ id: 'Common.PayWithCard' })}
                                    className="rd-button pay-with-card"
                                    onClick={() => redirectToCheckout()}
                                    disabled={payWithCardButtonDisabled()}
                                />
                            </Fragment>
                        }
                        {props.orderItems.length === 0 &&
                            <Button className="rd-button-outline select-voucher-button" onClick={() => props.history.push(`/${props.microsite}/`)}><FormattedMessage id={"Vouchers.SelectAVoucher"} /></Button>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default injectIntl(VoucherCartPage);