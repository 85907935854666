import React from 'react';
import ButtonWrapper from './Button';
import InputField from './InputField';

const NumericInputField = (props) => {

    function decrementItem() {
        var value = props.value - 1;
        if (props.min && value < props.min)
            return;

        props.onChangeHandler(value, props.itemId);
    }

    function incrementItem() {
        var value = props.value + 1;
        if (props.max && value > props.max)
            return;

        props.onChangeHandler(value, props.itemId);
    }

    function inputOnChangeHandler(value) {
        const parsedValue = parseInt(value); //typing into the field or using the arrow keys causes the value to be entered as a string
        if (isNaN(parsedValue) || (props.max && parsedValue > props.max) || (props.min && parsedValue < props.min))
            return;
        return props.onChangeHandler(parseInt(value));
    }

    return (
        <div className="input-group rd-input-numeric-container">
            <div className="input-group-prepend">
                <ButtonWrapper className="rd-button" text="-" onClick={decrementItem}></ButtonWrapper>
            </div>

            <InputField type={"number"} value={props.value} onChange={(e) => inputOnChangeHandler(e.target.value)} readOnly={props.readOnly} />

            <div className="input-group-append">
                <ButtonWrapper className="rd-button" text="+" onClick={incrementItem}></ButtonWrapper>
            </div>
        </div>
    );
}

export default NumericInputField;