import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const RestaurantTermsAndConditions = (props) => {
    const { intl } = props;

    RestaurantTermsAndConditions.propTypes = {
        termsAndConditions: PropTypes.string.isRequired
    };

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const cleanHTML = (input) => {
        return input.replace(/[\n\t]+/g, '');
    }

    return (
        <div>
            <Button className="rd-font-size-base d-block link-button" onClick={toggle}>{intl.formatMessage({ id: 'Common.VenueTermsAndConditions'})}</Button>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>{intl.formatMessage({ id: 'Common.VenueTermsAndConditions' })}</ModalHeader>
                <ModalBody>
                    <p dangerouslySetInnerHTML={{ __html: cleanHTML(props.termsAndConditions) }}></p>
                </ModalBody>
                <ModalFooter>
                    <Button className="rd-button" onClick={toggle}>{intl.formatMessage({ id: 'Common.Close' })}</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default injectIntl(RestaurantTermsAndConditions);